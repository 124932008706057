import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_GATEWAY+'api/user/';

class UserService {

  async getTeams(searchParams){
    if(!searchParams) searchParams = {};
    const u = "?"+ new URLSearchParams(searchParams).toString();
    return axios.get(API_URL + "getTeams"+u, { headers: authHeader() });
  }

  async getRoles() {
    return axios.get(API_URL + "getRoles", { headers: authHeader() });
  }

  async saveUser(user){
    return axios.post(API_URL + "saveUser", user, { headers: authHeader() });
  }

  async getOrganisations() {
    return axios.get(API_URL + "getOrganisations", { headers: authHeader() });
  }


  /**
   * Picks up the authenticates user ID from the request headers
   * @returns An array of alert objects for the autenticated user
   */
  async getAlertsForUser() {
    return axios.get(API_URL + 'getAlertsForUser', { headers: authHeader() });
  }


  /**
   * Returns a list of users
   * @returns 
   */
  async getAllUsers(searchParams) {
    if(!searchParams) searchParams = {};
    const u = "?"+ new URLSearchParams(searchParams).toString();
    return axios.get(API_URL + 'fetchUsers'+u, { headers: authHeader() });
  }

  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();