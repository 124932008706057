import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, Paper, Select, Switch, TextField, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from "react";
import userService from '../../services/user.service';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import UserListEntry from "./user.list.entry.component";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const IGNORABLE = "0000000000000000";
const DEFAULT_EMPTY_USER = {
    name: "",
    email: "",
    password: "",
    shadowPassword: "",
    organisation: {name: "", _id:IGNORABLE},
    roles: [],
    teams: [],
}
const useStyles = makeStyles((theme) => ({

    board : {
        display: "Flex",
        justifyContent : "space-between",
        flexDirection: "column",
    },
    displayArea : {
        display: "Flex",
        justifyContent : "space-between",
        flexDirection: "row",
        gap: "1rem",
    },

    sidePanel : {
        display: "Flex",
        justifyContent : "space-between",
        flexGrow: "0",
        flexDirection: "column",
        padding: "1em",
        minWidth: "300px",
    },
    formPanel : {
        flexGrow: "1",
        padding: "1em",
    },

    list : {
        listStyleType: "none",
        padding: "0px",
        margin: "0px"
    },

    listItem : {
        borderBottom: "dotted grey 2px",
            '&:nth-last-child(1)': {
                borderBottom: 'none'
            },
    },
    formRow : {
        display: "Flex",
        justifyContent : "flex-start",
        flexDirection: "row",
        flexWrap: "nowrap",
        gap: "1rem",
        paddingBottom: "1em",
        paddingLeft: "1em",
    },
    formLabel : {
        flexbasis : "20%",
        minWidth: "10em",
        textAlign: "end",
    },
}));

export default function MaintainUsersScreen(props) {
    const classes = useStyles(props);
    const [didMount, setDidMount] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(IGNORABLE)
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({...DEFAULT_EMPTY_USER});
    const [searchterm, setSearchTerm] = useState("");
    const [includeInactive, setIncludeInactive] = useState(false);
    const [enablePWD, setEnablePWD] = useState(false);
    const passwordFieldRef = React.useRef();
    const viewPasswordButtonRef = React.useRef();
    const enablePasswordCheckBoxRef = React.useRef();
    
    /**
     * If the state changes, save to the back end
     */
    useEffect(() => {
        if(didMount){
            // Run only if the component has been mounted

        }else{
            userService.getAllUsers({}).then(response => {
                setUsers(prepUsers(response.data.users)); 
            });
            userService.getOrganisations().then(response => {
                setOrgList(response.data.organisations);
            });
            userService.getRoles().then(response => {
                setRoleList(response.data.roles);
            });
            setDidMount(true);
        }
    }, []);

    useEffect(() => {
        if(didMount){
            userService.getTeams({org: selectedUser.organisation._id}).then(response => {
                setTeamList(response.data.teams);
            })
        }
    }, [selectedUser])

    useEffect(() => {
        if(didMount){
            if(selectedOrg === IGNORABLE){
                userService.getAllUsers().then(response => {
                    setUsers(prepUsers(response.data.users)); 
                });
            } else {
                userService.getAllUsers({org: selectedOrg}).then(response => {
                    setUsers(prepUsers(response.data.users)); 
                });
            }
        }
    }, [selectedOrg]);

    function prepUsers(source){
        let users = [...source];
        for(let i=0; i<users.length; i++){
            if(users[i].organisation === undefined){
                users[i].organisation = {_id: IGNORABLE};
            }
            if(users[i].teams === undefined){
                users[i].teams = [];
            }
        }
        return users;
    }

    /**
     * Standard compare function to sort on name alpha
     * @param {first user} a 
     * @param {second user} b 
     * @returns 
     */
    function compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
        return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
        return 1;
        }
        return 0;
    }

    function saveUser(){
        userService.saveUser(selectedUser).then(response => {
            setSelectedUser({...DEFAULT_EMPTY_USER});
            enablePasswordCheckBoxRef.current.checked = false;
            setEnablePWD(false);
            if(selectedOrg === IGNORABLE){
                userService.getAllUsers().then(response => {
                    setUsers(response.data.users); 
                });
            } else {
                userService.getAllUsers({org: selectedOrg}).then(response => {
                    setUsers(response.data.users); 
                });
            }}
        );
    }

    return(
        <div className={classes.board}>
            <div id="controls" style={{ display: "flex", justifyContent: "flex-start", gap: "10px", verticalAlign: "middle" }}>
                <FormControl variant="filled" sx={{ m: 1, minWidth: 275, margin: 0 }}>
                <Select
                  labelId="orgSelector"
                  id="orgSelector"
                  size="small"
                  onChange={(event) => {
                    setSelectedOrg(event.target.value);
                  }}
                  value={selectedOrg}>
                  <MenuItem size="small" value={IGNORABLE}>All organisations</MenuItem>
                  {
                    orgList.map(function (org) {
                      return <MenuItem size="small" key={org._id} value={org._id}>{org.name} {(org.active ? "" : " (Inactive)" )}</MenuItem>
                    })
                  }
                </Select>

                </FormControl>
                <FormControl variant="filled">
                    <TextField id="search" label="Search users" variant="outlined" size="small" style={{marginLeft: "1em", marginRight: "1em"}}
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}/>
                </FormControl>

                <FormControl variant="filled">
                    <FormControlLabel control={
                        <Switch 
                            checked={Boolean(includeInactive)} 
                            onChange={(event)=>{
                                setIncludeInactive(!includeInactive)
                    }}/>} label="Include Inactive" />
                </FormControl>


                <FormControl variant="filled">
                    <Button id="createNewUser" size="small" variant="contained" startIcon={<AddCircleIcon />}>New User</Button>
                </FormControl>
            </div>
            <div id="displayArea" className={classes.displayArea}>
                <div id="sidePanel" className={classes.sidePanel}>
                <Paper elevation={12} style={{padding: "1em"}}>
                    <div>
                        <ul className={classes.list}>
                            {users !== undefined && users.filter(user => {
                                return (searchterm !== "" ? (user.name.toLowerCase().includes(searchterm.toLowerCase()) || 
                                                            user.email.toLowerCase().includes(searchterm.toLowerCase())) : 
                                                            true);
                                }).filter(user => {
                                    return (includeInactive || user.active)
                                }).sort(compare).map(function(user, index){
                                return (
                                    <li key={user.email} user={user} className={classes.listItem} onClick={()=>{setSelectedUser({...user, changePWD: false, showPWD: false})}}>
                                        <UserListEntry user={user}/>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    </Paper>
                </div>
                <div id="contentPanel" className={classes.formPanel}>
                    <Paper elevation={12} style={{padding: "1em"}}>
                        <div id="userForm">
                            <div id="nameRow" className={classes.formRow}>
                                <div id="nameLabel" className={classes.formLabel}><Typography>Name</Typography></div>
                                <div>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 275, margin: 0 }} required>
                                        <TextField value={selectedUser.name} variant="outlined" size="small" style={{marginLeft: "0", marginRight: "1em"}}
                                        onChange={(event) => {
                                            setSelectedUser({...selectedUser, name: event.target.value})
                                        }}/>
                                    </FormControl>
                                </div>
                            </div>
                            <div id="emailRow" className={classes.formRow}>
                                <div id="emailLabel" className={classes.formLabel}><Typography>Email</Typography></div>
                                <div>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 275, margin: 0 }} required>
                                        <TextField value={selectedUser.email} variant="outlined" size="small" style={{marginLeft: "0", marginRight: "1em"}}
                                        onChange={(event) => {
                                            setSelectedUser({...selectedUser, email: event.target.value})
                                        }}/>
                                    </FormControl>                                    
                                </div>
                            </div>
                            <div id="passwordRow" className={classes.formRow}>
                                <div id="passwordLabel" className={classes.formLabel}><Typography>Password</Typography></div>
                                <div>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 275, margin: 0 }} required disabled={!enablePWD} ref={passwordFieldRef}>
                                    <OutlinedInput
                                        type={selectedUser.showPWD ? 'text' : 'password'}
                                        value={selectedUser.password}
                                        onChange={(event) => {
                                            setSelectedUser({...selectedUser, password: event.target.value})
                                        }}
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            onClick={()=>{setSelectedUser({...selectedUser, showPWD: !selectedUser.showPWD})}}
                                            onMouseDown={()=>{}}
                                            edge="end"
                                            disabled={!enablePWD}
                                            ref={viewPasswordButtonRef}>
                                            {false ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Password"/>
                                    </FormControl>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 275, marginLeft: "2em" }}>
                                        <FormControlLabel control={<Checkbox inputRef={enablePasswordCheckBoxRef}
                                        onClick={(event)=>{
                                            setEnablePWD(!enablePWD);
                                        }}/>} label="Change Password" />
                                    </FormControl>
                                </div>
                            </div>
                            <div id="activeRow" className={classes.formRow}>
                                <div id="activeLabel" className={classes.formLabel}><Typography>Active</Typography></div>
                                <div>
                                <FormControlLabel 
                                    style={{marginLeft: "1em", marginRight: "1em"}}
                                    control={
                                    <Switch size="small" color="primary"
                                        checked={selectedUser.active}
                                        onChange={(event, index) => {
                                            setSelectedUser({ ...selectedUser, active : (selectedUser.active ? false : true) });
                                        }}
                                    />}
                                />
                                </div>
                            </div>
                            <div id="stuff" className={classes.formRow}>
                                <div id="activeLabel" className={classes.formLabel}><Typography>Organisation</Typography></div>
                                <div>        
                                    <FormControl sx={{ m: 1, minWidth: 275, margin: 0 }}>
                                        <Select
                                        labelId="orgSelector"
                                        id="orgSelector"
                                        size="small"
                                        onChange={(event) => {
                                            setSelectedUser({...selectedUser, organisation: {_id: event.target.value}, teams: [], roles: []});
                                            console.log("Org select change triggered") &&
                                            userService.getTeams({org: selectedOrg}).then(response => {
                                                console.dir(response.data);
                                                setTeamList(response.data.teams); 
                                            })
                                        }}
                                        value={selectedUser.organisation._id}>
                                        <MenuItem size="small" value={IGNORABLE}>None</MenuItem>
                                        {
                                            orgList.map(function (org) {
                                            return <MenuItem size="small" key={org._id} value={org._id}>{org.name} {(org.active ? "" : " (Inactive)" )}</MenuItem>
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div id="stuff" className={classes.formRow}>
                                <div id="activeLabel" className={classes.formLabel}><Typography>Roles</Typography></div>
                                <div>
                                    <FormControl sx={{ m: 1, minWidth: 275, margin: 0 }}>
                                        <Select
                                        multiple
                                        value={selectedUser.roles.map(role => role.name)}
                                        onChange={(event)=>{
                                            let filteredList = roleList.filter((role) => {
                                                return event.target.value.includes(role.name)
                                            })
                                            setSelectedUser({...selectedUser, roles: filteredList})
                                        }}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                              ))}
                                            </Box>
                                          )}
                                        >
                                        {roleList && roleList.map((role) => (
                                            <MenuItem key={role.name} value={role.name}>
                                                <Checkbox checked={selectedUser.roles.map(role => role.name).includes(role.name)} />
                                                <ListItemText primary={role.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div id="stuff" className={classes.formRow}>
                                <div id="teamsLabel" className={classes.formLabel}><Typography>Teams</Typography></div>
                                <div>
                                    <FormControl sx={{ m: 1, minWidth: 275, margin: 0 }}>
                                        <Select
                                        multiple
                                        value={selectedUser.teams.map(team => team.name)}
                                        onChange={(event)=>{
                                            let filteredList = teamList.filter((team) => {
                                                return event.target.value.includes(team.name)
                                            })
                                            setSelectedUser({...selectedUser, teams: filteredList})
                                        }}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                              ))}
                                            </Box>
                                          )}
                                        >
                                        {teamList && teamList.map(team => team.name).map((teamName) => (
                                            <MenuItem key={teamName} value={teamName}>
                                                <Checkbox checked={selectedUser.teams.map(team => team.name).includes(teamName)} />
                                                <ListItemText primary={teamName} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", verticalAlign: "middle", marginBottom: "1em", marginTop: "1em", paddingTop: "1em", borderTop: "2px solid black"}}>
                                <Button size="small" variant="contained" disabled={selectedUser.email === undefined} startIcon={<SaveIcon />} onClick={saveUser}>Save</Button>
                                <Button size="small" variant="contained" disabled={selectedUser._id === undefined} color="warning" startIcon={<DeleteForeverIcon />}>Delete</Button>
                                <Button size="small" variant="contained" disabled={selectedUser.name === undefined} color="info" startIcon={<CancelIcon />} onClick={()=>{
                                    setSelectedUser({...DEFAULT_EMPTY_USER});
                                    }}>Cancel</Button>
                            </div>

                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
};