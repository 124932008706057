import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from "react";


const useStyles = makeStyles((theme) => ({

    board : {

    },

}));

export default function MaintainTeamsScreen(props) {
    const classes = useStyles(props);
    const [didMount, setDidMount] = useState(false);
    
    //TODO: Don't do this on the first one...
    /**
     * If the state changes, save to the back end
     */
    useEffect(() => {
        if(didMount){}
        setDidMount(true);
        
    }, [])

    return(
        <div className={classes.board}>
            <div id="sidePanel">
                <div>Search box</div>
                <div>New Team Button</div>
                <div>List<br/>As you search, disable the ones that don't match the criteria</div>
            </div>
            <div id="contentPanel">
                When you select one from the list, show the form
            </div>
        </div>
    )
};