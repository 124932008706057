import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@mui/material/TextField';

function EditNodeForm(props) {
    const useStyles = makeStyles((theme) => ({  
        editForm : {
            display: "inline-block",
            backgroundColor: "#f2f2f2",
            opacity: "1",
            color: "black",
            fontWeight: "bold",
            textAlign: "left",
            padding: "1em",
            border: "2px solid black",
            borderRadius: "5px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        },
        formInput : {
            backgroundColor: "white",
            margin: "1em",
            width: "275px",
        },
        saveButton : {
            backgroundColor: "green",
            color: "white",
            fontWeight: "bold",
            padding: "1em",
            paddingLeft: "2em",
            paddingRight: "2em",
            borderRadius: "5px",
        },
        cancelButton : {
            backgroundColor: "red",
            color: "white",
            fontWeight: "bold",
            padding: "1em",
            paddingLeft: "2em",
            paddingRight: "2em",
            borderRadius: "5px",
        },
        overlay : {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "lightgrey",
            background: "rgba(0, 0, 0, 0.4)",
        },
        centered : {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    }));
    const [text, setText] = useState(props.node.text);
    const [description, setDescription] = useState(props.node.description);
    const [color, setColor] = useState(props.node.color);
    const [URL, setURL] = useState(props.node.URL);

    const classes = useStyles(props);
    const setShowEdit = props.closeFunction;
    const saveNode = () => {
        props.saveFunction({...props.node, text: text, URL: URL, description: description, color: color});
        setShowEdit(false);
    }

  return (
    
    <React.Fragment>
        <div className={classes.overlay} >
            <div className={`${classes.editForm} ${classes.centered}`}>
                <div style={{padding: "1em"}}><TextField className={classes.formInput} label="Prompt Text" id="labelFormInput" value={text} size="small" onChange={(event) => {setText(event.target.value)}}/></div>
                <div style={{padding: "1em"}}><TextField className={classes.formInput} label="URL" id="URLFormInput" value={URL} size="small" onChange={(event) => {setURL(event.target.value)}}/></div>
                <div style={{padding: "1em"}}><TextField className={classes.formInput} label="Description" id="descriptionFormInput" value={description} size="small" multiline rows={4} onChange={(event) => {setDescription(event.target.value)}}/></div>
                <div style={{padding: "1em"}}><TextField className={classes.formInput} label="Background Color" id="colorFormInput" value={color} size="small" onChange={(event) => {setColor(event.target.value)}}/></div>

                <div style={{padding: "1em", display: "flex", justifyContent: "space-between"}}>
                    <button className={classes.saveButton} onClick={saveNode}>Save</button>
                    <button className={classes.cancelButton} onClick={() => {setShowEdit(false)}}>Cancel</button>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default EditNodeForm