import React, { Component } from "react";

export default class Decomposition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    return (
        <div className="container">
            <header className="jumbotron">
            User Story Decomposition
            </header>
            <div>You want to break down user stories into the smallest viable unit of work that delivers value. That value may be direct value to the customer or it may be an architectural or technical dependency that unlocks other user stories.</div>
            <div>
                <p>Take for instance a user story around enabling a customer to save a new account preference (say, whether they want to receive marketing emails or not). The database doesn't have a field for that, the user profile services don't know about that field, and the account settings page doesn't have a selector for that preference. All together, that could be a large story to build in one go - and the larger a given story is the more chance there is of something coming along and disrupting it.</p>
                <p>So, break that into separate stories:
                    <ol>
                        <li>Add the new field to the database table or structure</li>
                        <li>Add the new field to the account profile retrieval service</li>
                        <li>Implement the opt out/in service</li>
                        <li>Add the opt out/in toggle into the account settings page and persist via the new service</li>
                    </ol> 
                </p>
                <p>
                    What might have started as an 8-point story (relatively large) might then break down into four 2 or 3 point stories. That could mean a higher estimate overall, but the ability to work on separate components, each separately delivering technical value, means that there is less risk of disruption to the whole should there be a problem with any one element. What kind of problem? Well, what if the UI (that was signed-off) is suddenly reviewed and revised without there being time in the iteration to change it. Now, you've hit the end of iteration, all that work done, but it needs to be reverted as you cannot put untested unaccepted work into the release candidate. Or the DB change makes for an unexpected performance issue via an indexing issue nobody thought of... There could be any number of issues that could disrupt a single larger story, so breaking it down limits and contains risk to relatively fewer story points at a time.
                </p>
                </div>
            <div>
                <h3>How should we break the story down?</h3>
                <p>There are a number of approaches and your team can take the approach that best suits them and the work in hand.</p>
                <p>By technology tier: Split by model, view, controller pattern - DB, service API, UI, etc...</p>
                <p>Need to make the change across several pages? Split by page and implement across the journey gradually...</p>
                <p>Split by customer type - roll out by subscriber tier, region, or language...</p>
            </div>
        </div>
    )}
}