import { Typography } from '@mui/material'
import React from 'react'

export default function DiscoveryInstructions() {
  return (
    <div>
        <div><Typography variant='h3'>Backlog Discovery and BDD</Typography></div>
        <div>Use the controls above to choose a backlog item to explore with your team, or create a new Epic and start there.</div>
        <div stle={{display: "flex", flexDirection: "row", gap: "1em", justifyContent: "space-around"}}>
            <div>
                <p>The aim of the discovery process is to identify and answer questions about each backlog item, whether that's an epic, a feature, or a user story.</p>
                <p>Once you have selected a backlog item, identify any rules that apply to the functionality (who might use the functionality, any effective dates, etc,) and any examples that need specific testing (such as an example before the effective date, one on the date, one after, what happens at midnight, etc.). These inform the acceptance criteria for the backlog item.</p>
            </div>

            <div>
                <p>In the real world, we might use a blank wall and stick post-it notes up. We typically use set colours for set items:</p>
                <ul>
                    <li>Yellow for the backlog item</li>
                    <li>Red for open quetions the team need to take away and investigate</li>
                    <li>Blue for rules that describe how the functionality should work and the constraints within which it must operate</li>
                    <li>Green for examples that explain the rules - what we should test against - and we'll have one or more of these per blue rule card</li>
                </ul>
            </div>
            <div>
                <Typography variant='h5'>What does it tell us?</Typography>
                <p>Lots of red cards, lots of questions, tells us that we don't know enough about the backlog item yet, that we have a lot to still discover until we can approach development with confidence.</p>
                <p>Lots of blue cards, lots of rules, suggests high complexity and size, lots to develop and test so we should look at ways to split the story into smaller separate stories.</p>
                <p>Lots of green cards, lots of rules, shows additional complexity, lots of specifics that need to be tested.</p>
            </div>
        </div>

    </div>
  )
}