import { FormControlLabel, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    display:{
        display: "flex",
        flexDirection: "row",
        gap: "1em",
    },
    spacer : {

    }
}));

export default function UserListEntry(props) {

    const classes = useStyles(props);
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(props.user);

    useEffect(() => {
        if(didMount){
            // Run only if the component has been mounted
            // Save changes to the DB
            // So we need the service object here
            setUser(props.user);
        }else{
            setDidMount(true);
        }
    }, [props.user])

    return (
        <div className={classes.display}>
            <div>
                <FormControlLabel control={
                    <Switch size="small" color="primary"
                        checked={user.active}
                        onChange={(event, index) => {
                            setUser({ ...user, active : (user.active ? false : true) });
                        }}
                        disabled={true}
                    />}
                />
            </div>
            <div>
                <span className={classes.spacer}>{user.name}</span><br/>
                <span>{user.email}</span><br/>
                <span>{user.organisation.name}</span>
            </div>
        </div>
    )
}