import React, { Component } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import Box from '@material-ui/core/Box';
import PrimaryAppBar from "./components/menu.component";
import AuthService from "./services/auth.service";

import Login from "./components/user/login.component";
import Register from "./components/user/register.component";
import Home from "./components/home.component";
import Profile from "./components/user/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import Development from "./components/development/development.component";
import RegistrationSuccess from "./components/user/registersuccess.component";
import ProtectedRoute from "./components/common/protectedroute.component";
import Footer from "./components/common/footer.component";
import Decomposition from "./components/estimating/decomposition.component";
import FeatureSizing from "./components/feature/featuresizing.component";
import UnauthRoute from "./components/common/unauthroute.component";
import Todos from "./components/todos/todos.component";
import Snippets from "./components/snippets/snippets.component";
import Journal from "./components/journal/journal.component";
import AdminScreen from "./components/admin/admin.component";
import Discovery from "./components/discovery/discovery.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { currentUser } = this.state;
    const boxy = currentUser ? { marginLeft: "1em", marginRight: "1em", justifyContent:"top", flexGrow:2} : {justifyContent:"top"};

    return (
      <>
          <div className="App">
          <BrowserRouter>
            {currentUser ? (<PrimaryAppBar currentuser={currentUser} />) : (<span />)}
            <Box style={boxy}>
              <div>
              
                <Switch>
                  <Route path="/pricing" component={Development} />

                  <UnauthRoute exact path={["/", "/login", "/signin"]} component={Login} />
                  <UnauthRoute exact path="/register" component={Register} />
                  <UnauthRoute exact path="/registrationsuccess" component={RegistrationSuccess} />

                  <ProtectedRoute path="/home" component={Home} />
                  <ProtectedRoute path="/profile" component={Profile} />
                  <ProtectedRoute path="/user" component={BoardUser} />
                  <ProtectedRoute path="/mod" component={BoardModerator} />
                  <ProtectedRoute path="/admin" component={AdminScreen} />
                  <ProtectedRoute path="/development" component={Development} />
                  <ProtectedRoute path="/decompose" component={Decomposition} />
                  <ProtectedRoute path="/featuresizing" component={FeatureSizing} />
                  <ProtectedRoute path="/todos" component={Todos} />
                  <ProtectedRoute path="/snippets" component={Snippets} />
                  <ProtectedRoute path="/discovery" component={Discovery} />
                  <ProtectedRoute path="/discovery/epic/:epicID" component={Discovery} />
                  <ProtectedRoute path="/discovery/feature/:featureID" component={Discovery} />
                  <ProtectedRoute path="/discovery/story/:storyID" component={Discovery} />
                  <ProtectedRoute path="/journal" component={Journal} />

                </Switch>
              </div>
            </Box>
            <Footer />
            </BrowserRouter>
          </div>
          
      </>
    );
  }
}

export default App;