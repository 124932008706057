import React, { Component } from "react";
import DiscoveryService from "../../services/discovery.service";
import DiscoveryTree from "./discoverytree.component";
import { Button, FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AddCircleIcon from '@mui/icons-material/AddCircle';


export default class DiscoveryPrompt extends Component {

  state = {
    currentTree: {},
    treeUUID: "0000000000000000",
    treeName: "",
    isLoadingList: true,
    isLoadingTree: true,
    treeList: [],
    newTreeName: "",
  };

  constructor(props) {
    super(props);

    // Set up a controls object that contains a set of functions so that we can pass this to the tree display
    // The tree display can then use those functions in things like context menus
    this.controls = {
      updateNode: this.updateRootNode.bind(this),
      updateTree: this.updateTree.bind(this),
      saveNewTree: this.saveNewTree.bind(this),
    };

  }
  /*
   * When we've mounted this component, we need to go fetch the data
   * So we have a 
   */
  componentDidMount() {
    DiscoveryService.getListOfTrees().then(response => {
      this.setState({ treeList: response.data.trees });
      this.setState({ isLoadingList: false });
    });
  }

  loadtree(){
    DiscoveryService.getDecisionTree( this.state.treeUUID ).then(response => {
      this.setState({ currentTree: response.data.nodes[0] });
      this.setState({ uuid: response.data.uuid});
      this.setState({ name: response.data.name});
      this.setState({ isLoadingTree: false });
    });
  }  

  /**
   * 
   */
  createEmptyTree(){
    if(!this.state.newTreeName) return;
    DiscoveryService.newDefaultDecisionTree(this.state.newTreeName).then(response => {
      this.setState( {newTreeName: ""} );
      this.setState( {treeList: [...this.state.treeList, response.data.tree] })
      this.setState({ treeUUID: response.data.tree.uuid });
    });
  }

  updateRootNode(node){
    this.setState({currentTree: node});
  }

  saveTreeToDB(){
    const { treeUUID, name, currentTree } = this.state;
    DiscoveryService.saveDecisionTree({
      uuid: treeUUID,
      name: name,
      nodes: [
        currentTree
      ]
    }).then(response => {
      //console.log(response);
    });
  }

  /**
   * Save the passed tree structure
   * Or the current state tree if we're not passed anything
   */
   updateTree(tree){
    console.log(tree + "passed in");
    console.log(this);
    if(!tree) tree = {...this.state.currentTree};

    this.setState({
      currentTree:tree
    }, 
      ()=>{this.saveTreeToDB()}
    )
  };

  /**
   * Save a new tree
   */
   saveNewTree(){
    const { uuid, name, currentTree } = this.state;
    DiscoveryService.newDecisionTree({
      uuid: uuid,
      name: name,
      nodes: [
        currentTree
      ]
    }).then(response => {
      console.log(response);
    });
  };


  render() {
    const { isLoadingList } = this.state;
    if (isLoadingList) {
      return (
        <React.Fragment>
          <canvas id="canvas" style={{ position: "absolute", top: "0", left: "0", zIndex: "-1" }}> </canvas>
          <div>Loading data...</div>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <main>
          <div id="headerdiv">
            <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px", verticalAlign: "bottom" }}>

              <FormControl sx={{ m: 1, minWidth: 250 }}>
                
                <Select
                  labelId="treeSelector"
                  id="discoverTreeSelector"
                  onChange={(event) => {
                    this.setState({ treeUUID: event.target.value }, this.loadTree);
                  }}
                  value={this.state.treeUUID}>
                  <MenuItem value={"0000000000000000"}><em>No Tree Selected</em></MenuItem>
                  {
                    this.state.treeList.map(function (treeDetail, index) {
                      return <MenuItem key={treeDetail.uuid} value={treeDetail.uuid}>{treeDetail.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel htmlFor="newTreeName">New discovery tree name</InputLabel>
                <Input id="newTreeName" value={this.state.newTreeName} onChange={
                  (event) => {
                    this.setState({newTreeName: event.target.value})
                  }
                  }/>
              </FormControl>

              <FormControl variant="filled" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button id="createNewTree" variant="contained" startIcon={<AddCircleIcon />} onClick={this.createEmptyTree.bind(this)}>New Tree</Button>
              </FormControl>
              <FormControl variant="filled" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button id="saveAsNewTree" variant="contained" startIcon={<SaveAsIcon />}>Save As...</Button>
              </FormControl>
              <FormControl variant="filled" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button id="saveCurrentTree" variant="contained" startIcon={<SaveIcon />} onClick={this.saveTreeToDB.bind(this)}>Save</Button>
              </FormControl>

            </div>

          </div>

          <div id="treeViewDiv" style={{ alignItems: "center", alignContent: "center", justifyContent: "center", textAlign: "center", whiteSpace: "nowrap" }}>
            <DiscoveryTree uuid={this.state.treeUUID} tree={this.state.currentTree} controls={this.controls}/>
          </div>

          <div id="footerdiv">
            <p>Use this util to drive discovery. It can prompt on questions and help discussion along.</p>
            <p>Labels with a dot on the bottom border can be expanded further to see more choices and decision points.</p>
          </div>
        </main>
      </React.Fragment>
    );
  }
}