import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tag from './tag.component';

const useStyles = makeStyles((theme) => ({
    taglist : {
        marginTop: "5px",
        verticalAlign: "middle",
    },
    addTagButton : {
        marginLeft: "2px",
        textAlign: "center",
        verticalAlign: "middle",
        margin: "auto",
        padding: "auto",
        display: "inline-block",
        height: ".9em",
        width: ".9em",
        lineHeight: ".9em",
        borderRadius: "50%",
        backgroundColor: "grey",
        color: "white",
        float: "right",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
        '&:hover': {
            cursor: "default",
          }
    }
}));

export default function TagList(props) {
    const classes = useStyles(props);
    let label = "";
    if (props.tags === undefined || props.tags.length === 0) {
        label = <span>Tags</span>;
    }

    return (
        <Fragment>
            <div className={classes.taglist}>
                {label}
                {props.tags !== undefined && props.tags.map(function(tag){
                    return (
                        <Tag key={tag} tag={tag} deleteTag={props.deleteTag}/>
                    )})
                }
                <span className={classes.addTagButton} onClick={props.promptToAddTag}>+</span>
            </div>
        </Fragment>
    )
}