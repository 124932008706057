import React from "react";
import { Redirect, Route } from "react-router-dom";

function UnauthRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = ( null != JSON.parse(localStorage.getItem('user')) );

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/home" />
      }
    />
  );
}

export default UnauthRoute;