import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import ClickAwayListener from '@mui/base/ClickAwayListener';
import EditNodeForm from './editNodeForm.component';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({

    treeNode : {
        display: "inline-block",
        minWidth: "50px",
        marginLeft: "3px",
        marginRight: "3px",
        marginTop: "10px",
        marginBottom: "10px",
        verticalAlign: "top",
    },

    nodesContainer : {
        display: "block",
        marginTop: "5px",
        marginBottom: "5px",
        paddingTop: "2px",
        paddingBottom: "2px"
    },

    treeNodeLabel :{
        backgroundColor: "red",//"#3f50b5",
        color: "white",
        fontWeight: "bold",
        marginLeft: "3px",
        marginRight: "3px",
        marginTop: "10px",
        marginBottom: "10px",
        padding: "7px",
        display: "inline-block",
        minWidth: "6em",
        maxWidth: "12em",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },

    expandable : {
        outline: "dotted 1px black"
    },

    closed : {
        display: "none"
    },
    open : {

    },

    contextMenu : {
        backgroundColor: "#666666",
        color: "white",
        fontWeight: "bold",
        opacity: "0.9",
        textAlign: "left",
        paddingLeft: "5px",
        paddingRight: "3px",
        paddingTop: "3px",
        paddingBottom: "3px",
        minWidth: "150px",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },

    contextMenuItem : {
        listStyle: "none",
        paddingLeft: "3px",
        marginRight: "5px",
        paddingBottom: "7px",
        borderRadius: "5px",
        '&:hover': {
            backgroundColor: "#404040",
          }
    },

    contextMenuLink : {
        color: "white",
        textDecoration: "none"
    },

    centered : {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    dotted : {
        '&::before' : {
            content: "",
            height: "8px",
            width: "8px",
            backgroundColor: "#C29B4C",
            borderRadius: "50%",
            display: "inline-block",
            position: "absolute",
            bottom: "-4px",
            left: "50%",
        }
    },
    link : {
        color: "white",
        fontWeight: "bold",
    },

}));

export default function DiscoveryNode(props) {
    if(props.node.nodes === undefined){
        props.node.nodes = [];
    }
    if(props.node.uuid === undefined){
        props.node.uuid = generateUUID();
    }
    if(props.node.text === undefined){
        props.node.text = "What the..?";
    }
    if(props.node.visibility === undefined){
        props.node.visibility = "closed";
    }

    const classes = useStyles(props);
    const [node, setNode] = useState(props.node);
    // A couple of state vars for the context menu
    const [showContext, setShowContext] = useState(false);
    const [contextPoint, setContextPoint] = useState( {x:0, y:0} );
    const [showEdit, setShowEdit] = useState(false);
    const didMount = useRef(false);
    const nodeColor = useRef(props.node.color);

    let nodeStyleObj = {};
    if(node.color){
        nodeStyleObj.backgroundColor = node.color;
    }else if(props.color){
        setNode({...node, color: props.color})
        console.log(node.text +" is (props) "+ props.color);
        nodeStyleObj.backgroundColor = props.color;
    }

    /**
     * If the node state changes, we need to chain the change up to the next level
     */
     useEffect(()=>{
        if ( !didMount.current ) {
            return didMount.current = true;
        }
       
        props.updateNode(node);
    }, [node]);


    function updateChildrenColor(myNode){
        if(myNode.nodes){
            let temp = [...myNode.nodes];
            temp.map((item) => {
                item.color = myNode.color;
                updateChildrenColor(item);
                return item;
            });
            setNode({...node, nodes: temp})
        }
    }

    useEffect(()=>{
        if (nodeColor.current !== node.color){
            updateChildrenColor(node);
            nodeColor.current = node.color;
        }
    }, [node.color]);

    function generateUUID() {
        var d = new Date().getTime(); //Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if (d > 0) { //Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else { //Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x8)).toString(16);
        });
    }

    function addDummyNode(e){
        e.preventDefault();
        // TODO: Need a form in front of this method call, of course, of course...
        updateNode({text: "Added...", visibility: "open", uuid: generateUUID(), nodes: []});
    }

    /**
     * Asks for the given node to be removed from the parent
     * @param {The node to be deleted} item 
     */
    function deleteNode(item){
        let itemIndex = node.nodes.findIndex(entry => entry.uuid === item.uuid);
        // If we don't find it, just return
        if(itemIndex <0){
            return;
        }
        // If we have it, we need to remove it from the list
        node.nodes.splice(itemIndex, 1);
        // Now chain the update back up the tree
        props.updateNode(node)
    }

    /**
     * If we're being called, then the provided item is either an existing node in the nodes array or we're adding a new one
     * So... We need to take in an updated child node
     * @param {*} item 
     */
    function updateNode(item) { 
        // We need to look through the array and compare the array item's uuid to the uuid of the supplied node
        let itemIndex = node.nodes.findIndex(entry => entry.uuid === item.uuid);
        // If we find it in the array, then replace the item
        // Otherwise, add a new node to the array
        if (itemIndex > -1){
            node.nodes[itemIndex] = item;
            const copyOfNodeArray = [];
            node.nodes.forEach(val => copyOfNodeArray.push(Object.assign({}, val)));
            setNode({...node, nodes: copyOfNodeArray});
        } else {
            const copyOfNodeArray = [];
            node.nodes.forEach(val => copyOfNodeArray.push(Object.assign({}, val)));
            copyOfNodeArray.push(item);
            setNode({...node, nodes: copyOfNodeArray});
        }
        // Now chain it back up the tree
        props.updateNode(node);
    }

    // Click handler function - differentiates between single and double click
    const nodeClick = (e) => {
        e.preventDefault();
        switch (e.detail) {
          case 1: {
            singleClick(e);
            break;
          }
          case 2: {
            doubleClick(e);
            break;
          }
          default: {
            break;
          }
        }
    }

    /**
     * Click handler for open/close node behaviour
     */
    const singleClick = (e) => {
        if(node.visibility === "closed"){
            setNode({...node, visibility: "open"});
        }else{
            setNode({...node, visibility: "closed"});
        }
    }

    /**
     * Handle the context menu options
     * @param {click event} event
     */
    const contextClick = (event) => {
        event.preventDefault();
        setContextPoint({...contextPoint, x: event.clientX, y: event.clientY});
        setShowContext(true);
    }

    /**
     * We just need to switch off this component's context menu
     */
    const handleClickAway = (event) =>{
        //event.preventDefault();
        setShowContext(false);
    }

    /**
     * Need a useEffect keyed to the showContext
     */
    useEffect(()=>{
        if ( !didMount.current ) {
            return didMount.current = true;
        }
    }, [showContext]);


    // Handle UI double-click against the given event
    const doubleClick = (e) => {
        // If this is NOT a double-click, just return
        //Want to open a dialog to edit the node content
        // NOT CURRENTLY ABLE TO DETECT THIS AS A DISCRETE EVENT
    }

    const saveTree = (e) => {
        e.preventDefault();
        setShowContext(false);
        props.saveTree();
    }

    const saveNew = (e) => {
        e.preventDefault();
        setShowContext(false);
        props.saveNew();
    }

    const showEditDialog = (e) =>{
        e.preventDefault();
        setShowContext(false);
        setShowEdit(true);
    }

    const showAddDialog = (e) =>{
        e.preventDefault();
        setShowContext(false);
        addDummyNode(e); 
    }

    // TODO: Add a confirm prompt here
    // And if the prompt works - props.deleteNode
    const showDeleteConfirmation = (e) =>{
        e.preventDefault();
        setShowContext(false);
        if(window.confirm("Are you sure you want to delete this node?")){
            props.deleteNode(node);
        }
    } 

    const displayLabel = () => {
        if (node.URL) {
            return <a href={node.URL} className={classes.link} target={"_external"}>{node.text}</a>
        } else {
            return node.text;
        }
    }

      /**
       * The edit form for this node instance
       * @returns 
       */
      const editForm = (targetNode) =>{
        if (showEdit) {
            return <EditNodeForm node={node} closeFunction={setShowEdit} saveFunction={setNode}/>
        } else {
            return
        }
      }

    return (
        <React.Fragment>

            <div className={`${classes.treeNode} ${node.nodes.length !== 0 ? "expandable" : ""}`} level={props.level}>
                <Tooltip title={node.description ? node.description : ""} arrow>
                    <div className={`${classes.treeNodeLabel} ${classes.dotted}`} 
                        uuid={node.uuid} 
                        onClick={nodeClick} 
                        onContextMenu={contextClick}
                        style={nodeStyleObj}>
                        {displayLabel()}
                    </div>
                </Tooltip>

                {editForm(node)}

                <div className={`${classes.nodesContainer} nodescontainer ${(node.visibility === "closed") ? classes.closed : classes.open}`} level={props.level}>
                    {(node.nodes !== undefined && node.visibility !== "closed") && node.nodes.map(function(subnode, index){
                        return <DiscoveryNode key={subnode.uuid} node={subnode} level={props.level+1} color={node.color} updateNode={updateNode} deleteNode={deleteNode} saveTree={props.saveTree} saveNew={props.saveNew}/>
                    })}
                </div>

                <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
                <div className={`${classes.contextMenu} ${(!showContext) ? classes.closed : classes.open}`} 
                    style={{position: "absolute", top: contextPoint.y, left: contextPoint.x}}>
                    <ul style={{padding: "0"}}>
                        <li className={classes.contextMenuItem}><span onClick={showEditDialog}>Edit this prompt</span></li>
                        <li className={classes.contextMenuItem}><span onClick={showAddDialog}>Add another prompt</span></li>
                        <li className={classes.contextMenuItem}><span onClick={showDeleteConfirmation}>Delete this prompt</span></li>

                        {(node.URL !== "" && node.URL !== undefined) && <li className={classes.contextMenuItem}>
                            <span><a href={node.URL} rel="noopener noreferrer" target="_blank" className={classes.contextMenuLink}>Follow this URL</a></span>
                        </li>}

                        <li><hr style={{width: "85%", align: "left", marginLeft: "0", marginRight: "auto"}}/></li>
                        <li className={classes.contextMenuItem}><span onClick={saveTree}>Save tree</span></li>
                        <li className={classes.contextMenuItem}><span onClick={saveNew}>Save as new tree</span></li>
                    </ul>
                </div>
                </ClickAwayListener>
            </div>
        </React.Fragment>
    )
}