import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'

// Import a tag service?
// Or.. Do we need to actually pass down a function that removes tags from whatever the bounding entity is?
// So we'd pass a function, call that passing this tag ID, and that function would need to call an update on the entity's controller
// that updates the entity be removing the tag from the list and saving the list back

const useStyles = makeStyles((theme) => ({
    tag : {
        display: "inline-block",
        color: "black",
        backgroundColor: "#ebeced",
        opacity: ".9",
        borderRadius: "5px",
        marginRight: "3px",
        alignSelf: "flex-end;",
        padding: "2px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
        '&:hover': {
            cursor: "default",
          }
    },

    deleteButton : {
        marginLeft: "2px",
        textAlign: "center",
        verticalAlign: "middle",
        margin: "auto",
        padding: "auto",
        display: "inline-block",
        height: ".9em",
        width: ".9em",
        lineHeight: ".9em",
        borderRadius: "50%",
        backgroundColor: "grey",
        color: "white",
        '&:hover': {
            cursor: "default",
          }
    }
    
}));

export default function Tag(props) {
    const classes = useStyles(props);

    return (
        <Fragment>
            <div className={classes.tag}>
                <span>{props.tag}</span>
                <span className={classes.deleteButton} tag={props.tag} onClick={props.deleteTag}>x</span>
            </div>
        </Fragment>
    )
}