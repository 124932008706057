import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from "react";
import ToDoService from "../../services/todo.service";
import TagList from '../common/taglist.component';

const useStyles = makeStyles((theme) => ({

    todo : {
        minHeight: "4em",
        paddingTop: "1em",
        paddingLeft: "2em",
        paddingRight: "2em",
        paddingBottom: ".5em",
    },

    editableArea : {
        width: "100%",
        display: "inline-block",
        cursor: "text",
        padding: "2px",
    },

    pending : {
        backgroundColor: "lightgreen"
    },

    progress : {

    },

    done : {

    },
    archived : {

    }

}));

export default function Todo(props) {
    const classes = useStyles(props);
    const [todo, setTodo] = useState(props.todo);
    const [didMount, setDidMount] = useState(false);

    //TODO: Don't do this on the first one...
    /**
     * If the state changes, save to the back end
     */
    useEffect(() => {
        
        if(didMount){
            ToDoService.updateTodo(todo);
        } else {
            setDidMount(true);
        }
    }, [todo])

    const startEdit = (event) =>{
        event.target.setAttribute("contenteditable", "true");
    }

    /**
     * Once we've finished editing, set the todo text in state
     * @param {*} event 
     */
    const endEdit = (event) => {
        setTodo({...todo, title: event.target.innerHTML});
        event.target.removeAttribute("contenteditable");
    }

    const promptToAddTag = (event) => {
        event.preventDefault();
        let tag = prompt("New tag...");
        if(tag === undefined) return;
        if(props.todo.tags === undefined) props.todo.tags = [];
        if(props.todo.tags.includes(tag)) return;
        props.todo.tags = [...props.todo.tags, tag.toLowerCase()];
        setTodo({...todo, tags: props.todo.tags});
    }

    function removeItemOnce(arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    const deleteTag = (event) => {
        let newTagsArray = removeItemOnce(todo.tags, event.target.getAttribute("tag"));
        setTodo({...todo, tags: newTagsArray});
    }

    return(
        <div className={classes.todo}>
            <span className={classes.editableArea} onMouseDown={startEdit} onBlur={endEdit}>{todo.title}</span>
            <div>{todo.description}</div>
            <TagList style={{position: "absolute", bottom: "0"}}tags={todo.tags} promptToAddTag={promptToAddTag} deleteTag={deleteTag}/>
        </div>
    )
};