import React from "react";
import { Redirect, Route } from "react-router-dom";
import jwt from 'jwt-decode'

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let isAuthenticated = ( null != JSON.parse(localStorage.getItem('user')) );
  let token = "";
  
  if(isAuthenticated){
    // Fine... We can see that we have a user in the local storage, but what data do we have?
    // Where's the JWT?
    // And when does it expire?
    token = jwt(JSON.parse(localStorage.getItem('user')).accessToken);
    //console.log(token);
    //console.log(Date.now() + " / " + token.exp * 1000);
    //console.log(Date.now() > token.exp * 1000);

    // If the token has already expired, then clear the local storage, set the flag to false, and carry on
    if (Date.now() >= token.exp * 1000) {
      // TODO: Need to clear the token out of local storage
      isAuthenticated = false;
      localStorage.removeItem("user");
    } else {
      // If the token is still active, can we go get a refresh of that token and store it?
    }
  }
  

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}

export default ProtectedRoute;