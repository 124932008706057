import React, { useState, useEffect } from 'react'
import { Badge, IconButton } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications';
import userService from '../services/user.service';
import { useLocation } from 'react-router-dom';

export default function Alerts(props) {

    const location = useLocation();
    const [listOfAlerts, setListOfAlerts] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(0);

    useEffect(() =>{
        userService.getAlertsForUser().then(response => {
            setListOfAlerts(response.data.alerts);
        });
    }, [updateStatus, location]);

    return (
        <IconButton aria-label={`show ${listOfAlerts.length} new notifications`} color="inherit" onClick={()=>{setUpdateStatus(updateStatus+1)}}>
            <Badge badgeContent={listOfAlerts.length} color="secondary">
                <NotificationsIcon />
            </Badge>
        </IconButton>
    )
}