import { Grid, Typography } from "@material-ui/core";
import Snippet from "./snippet.component";
import React from "react";

export default function Snippets() {
    return(
        <Grid container>
            <Grid item><Typography>List of snippets goes here...</Typography></Grid>
            <Grid item><Snippet/></Grid>
        </Grid>
    )};