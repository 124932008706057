import React from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import Todo from './todo.component';

const useStyles = makeStyles((theme) => ({

    list : {
        marginBottom: "3em",
    },

    tasklist : {
        listStyleType: "none",
        padding: "0px",
        margin: "0px"
    },

    tasklistitem : {
        borderBottom: "dotted grey 2px",
            '&:nth-last-child(1)': {
                borderBottom: 'none'
            },
            '&:hover': {
                cursor: "move",
              }
    },
    stack : {
        backgroundColor: "lightgrey",
        textAlign: "center",
        width: "100%",
        borderRadius: "5px 5px 0px 0px",
    },
    pending : {
        backgroundColor: "lightgreen",
        textAlign: "center",
        borderRadius: "5px 5px 0px 0px",
    },

    progress : {
        backgroundColor: "orange",
        textAlign: "center",
        borderRadius: "5px 5px 0px 0px",
    },

    done : {
        backgroundColor: "grey",
        color: "white",
        textAlign: "center",
        borderRadius: "5px 5px 0px 0px",
    },
    archived : {
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        borderRadius: "5px 5px 0px 0px",
    },
    dragActive : {
        backgroundColor: "#EEEEEE"
    }
}));

/**
 * Standard compare function to sort on order
 * @param {first todo} a 
 * @param {second todo} b 
 * @returns 
 */
function compare( a, b ) {
    if ( a.order < b.order ){
      return -1;
    }
    if ( a.order > b.order ){
      return 1;
    }
    return 0;
  }

// So we're expecting to be passed a list of todo tasks
// We just need to display them - using the ToDo component
export default function TodoList(props) {
    const classes = useStyles(props);
    const stateClass = classes[props.state];
    return (
        <div className={classes.list}>
            <div className={stateClass}><Typography>{props.label}</Typography></div>
            <Droppable droppableId={props.state}>
                {(provided, snapshot) =>(
                    <ul {...provided.droppableProps} ref={provided.innerRef} className={`${snapshot.isDraggingOver ? "" : ""} `+classes.tasklist}>
                    {props.tasks !== undefined && props.tasks.filter(obj => {
                    return (obj.state === props.state) 
                    && (props.search !== "" ? (obj.title.toLowerCase().includes(props.search) || 
                                               obj.tags.join(",").toLowerCase().includes(props.search)) : 
                                               true);
                    }).sort(compare).map(function(todo, index){
                        todo.order = index
                        return (
                            <Draggable key={todo.title} draggableId={todo.title} index={index}>
                                {(provided, snapshot) => (
                                    <li key={todo.title} todo={todo} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={`${snapshot.isDragging ? classes.dragActive : ""} `+classes.tasklistitem}>
                                        <Todo key={todo.title} todo={todo} {...provided.draggableProps} {...provided.dragHandleProps} forwardRef={provided.innerRef}/>
                                    </li>
                                )}
                            </Draggable>
                        )
                    })}
                    {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </div>
    )
}