import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import AuthService from "../services/auth.service";
import NewTodo from './todos/newTodo.component';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import Alerts from './alerts.component';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    flexDirection: "row",
  },
  standardPadding: {
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "6px",
    paddingRight: "6px",
  },
  navbarBrand: {
    textDecoration: 'none',
    color: 'white',
    '&:visited': {
      color: 'white',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'block',
    fontSize: "16px",
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      fontSize: "20px",
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimaryAppBar() {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
};

  const logout = () => {
    AuthService.logout();
    handleMenuClose();
    history.push("/");
    window.location.reload();
  }

  const showModeratorContent = () => {
    return currentUser && currentUser.roles.includes("ROLE_MODERATOR");
  }

  const showAdminContent = () => {
    return currentUser && currentUser.roles.includes("ROLE_ADMIN");
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!currentUser ? (
        [
          <MenuItem component={Link} to="/home" onClick={handleMenuClose}>Home</MenuItem>,
          <MenuItem component={Link} to="/login" onClick={handleMenuClose}>Login</MenuItem>,
          <MenuItem component={Link} to="/register" onClick={handleMenuClose}>Register</MenuItem>
        ]
      ) : (
        [
          <Tooltip title={currentUser.name}>
            <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
              <IconButton aria-label="My Account" color="inherit">
                <SettingsIcon />
              </IconButton>
              <p>My Account</p>
            </MenuItem>
          </Tooltip>,
          <MenuItem component={Link} to="/home" onClick={logout}>
            <IconButton aria-label="Logout" color="inherit">
              <PowerSettingsNewIcon />
            </IconButton>
            <p>Logout</p>
          </MenuItem>
        ]
      )}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem component={Link} to="/home" onClick={handleMenuClose}>
        <IconButton aria-label="Home" color="inherit">
          <HomeIcon />
        </IconButton>
      </MenuItem>
      {showAdminContent() ? (
        <MenuItem component={Link} to="/admin" onClick={handleMenuClose}>
          <IconButton aria-label="Admin" color="inherit">
            <SettingsApplicationsIcon />
          </IconButton>
        </MenuItem>
      ) : (<span />)}
      {showModeratorContent() ? (
        <MenuItem component={Link} to="/mod" onClick={handleMenuClose}>
          <IconButton aria-label="Moderator" color="inherit">
            <GroupsIcon />
          </IconButton>
        </MenuItem>) : (<span />)}
    </Menu>
  );

  return (
    <div style={{ flexGrow: 0, flexDirection: "row", }}>
      {/*TODO: position fixed makes the app bar stay where it's supposed to, but it overlays the content, which doesn't always scroll*/}
      <AppBar position="sticky" >
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            AgilePlanning.tech
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip title="Home">
              <MenuItem component={Link} to="/home" onClick={handleMenuClose} className={classes.standardPadding}>
                <IconButton aria-label="Home" color="inherit" className={classes.standardPadding}>
                  <HomeIcon />
                </IconButton>
              </MenuItem>
            </Tooltip>
            <Tooltip title="Todo list">
              <MenuItem component={Link} to="/todos" onClick={handleMenuClose} className={classes.standardPadding}>
                <IconButton aria-label="Todos" color="inherit" className={classes.standardPadding}>
                  <FormatListBulletedIcon />
                </IconButton>
              </MenuItem>
            </Tooltip>
            <Tooltip title="Discovery">
              <MenuItem component={Link} to="/discovery" onClick={handleMenuClose} className={classes.standardPadding}>
                <IconButton aria-label="Discovery" color="inherit" className={classes.standardPadding}>
                  <QuestionAnswerIcon />
                </IconButton>
              </MenuItem>
            </Tooltip>
            <MenuItem className={classes.standardPadding}>
              <NewTodo theme="dark" enabled={!window.location.pathname.includes("todo")} />
            </MenuItem>

            {showAdminContent() ? (
              <Tooltip title="Admin">
                <MenuItem component={Link} to="/admin" onClick={handleMenuClose} className={classes.standardPadding}>
                  <IconButton aria-label="Admin" color="inherit" className={classes.standardPadding}>
                    <SettingsApplicationsIcon />
                  </IconButton>
                </MenuItem>
              </Tooltip>
            ) : (<span />)}
            {showModeratorContent() ? (
              <Tooltip title="Organisation Moderator">
                <MenuItem component={Link} to="/mod" onClick={handleMenuClose} className={classes.standardPadding}>
                  <IconButton aria-label="Moderator" color="inherit" className={classes.standardPadding}>
                    <GroupsIcon />
                  </IconButton>
                </MenuItem>
              </Tooltip>) : (<span />)}

            <IconButton aria-label="show 0 new mails" color="inherit" className={classes.standardPadding}>
              <Badge badgeContent={0} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <Alerts />
            <Tooltip title={currentUser.name}>
              <IconButton
                edge="end"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                className={classes.standardPadding}>
                <AccountCircle />
                {currentUser.name}
              </IconButton>
            </Tooltip>

          </div>
          <div className={classes.sectionMobile}>
            <IconButton onClick={handleMobileMenuOpen} color="inherit">
              <MoreVertIcon />
            </IconButton>
            <IconButton aria-label="show 0 new mails" color="inherit" className={classes.standardPadding}>
              <Badge badgeContent={0} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <Alerts />
            <Tooltip title={currentUser.name}>
              <IconButton
                edge="end"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                className={classes.standardPadding}>
                <AccountCircle />
                {currentUser.name}
              </IconButton>
            </Tooltip>
          </div>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}