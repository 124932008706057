import { Card, CardActionArea, CardContent, CardHeader, Typography } from "@material-ui/core";
import React from "react";

export default function Snippet() {
    return(
        <Card elevation={3}>
            <CardHeader><Typography>Code Snippet</Typography></CardHeader>
            <CardContent>
                This is a code snippet
            </CardContent>
            <CardActionArea></CardActionArea>
        </Card>
    )};