import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
export default function RegistrationSuccess() {
    return(
        <Grid container>
            <Grid item><Typography variant="h2">Welcome aboard!</Typography></Grid>
            <Grid item><Button component={Link} to="/login">Go to Login</Button></Grid>
        </Grid>
    )
}