import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormControl, FormControlLabel, Switch, TextField } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import discoveryService from '../../services/discovery.service';
import EpicDiscovery from './epic.discovery.component';
import DiscoveryInstructions from './discovery.instructions';

const IGNORABLE = "000000000000000000000000";
const MINSELECTWIDTH = "250";

const useStyles = makeStyles((theme) => ({

    board: {
        display: "Flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "1rem",
    },
    displayArea: {
        display: "Flex",
        justifyContent: "space-between",
        flexDirection: "row",
        gap: "1rem",
    },
    discoveryArea: {
        
    },
    basePaper: {
        padding: ".5em"
    }
}));

export default function DiscoveryBoard() {


    const EPICID = "epic";
    const FEATUREID = "feature";
    const STORYID = "story";
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const classes = useStyles();
    const [searchterm, setSearchTerm] = useState("");
    const [didMount, setDidMount] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(IGNORABLE);
    const [epicList, setEpicList] = useState([]);
    const [selectedEpic, setSelectedEpic] = useState((queryParameters.get(EPICID) ? queryParameters.get(EPICID) : IGNORABLE));
    const [featureList, setFeatureList] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState((queryParameters.get(FEATUREID) ? queryParameters.get(FEATUREID) : IGNORABLE));
    const [storyList, setStoryList] = useState([]);
    const [selectedStory, setSelectedStory] = useState((queryParameters.get(STORYID) ? queryParameters.get(STORYID) : IGNORABLE));
    const [includeInactive, setIncludeInactive] = useState(false);

    useEffect(() => {
        if (!didMount) {
            setDidMount(true);
            discoveryService.getTeamsForUsersOrganisation().then(response => {
                setTeamList(response.data.teams);
            });
            discoveryService.getEpics().then(response => {
                setEpicList(response.data.epics);
            });
            discoveryService.getFeatures().then(response => {
                setFeatureList(response.data.features);
            });
            discoveryService.getStories().then(response => {
                setStoryList(response.data.stories);
            });
        }
    });

    useEffect(()=> {
        discoveryService.getEpics(selectedTeam).then(response => {
            if(response.data.epics === []){
                setSelectedEpic(IGNORABLE);
            }
            setEpicList(response.data.epics);
        });
        discoveryService.getFeatures(selectedTeam).then(response => {
            if(response.data.features === []){
                setSelectedFeature(IGNORABLE);
            }
            setFeatureList(response.data.features);
        });
    }, [selectedTeam]);

    useEffect(()=> {
        discoveryService.getFeatures(selectedTeam, selectedEpic).then(response => {
            if(response.data.features === []){
                setSelectedFeature(IGNORABLE);
            }
            setFeatureList(response.data.features);
        });
    }, [selectedEpic]);

    useEffect(()=> {
        discoveryService.getStories(selectedTeam, selectedFeature).then(response => {
            if(response.data.stories === []){
                setSelectedStory(IGNORABLE);
            }
            setStoryList(response.data.stories);
        });
    }, [selectedFeature]);


    /**
     * a callback function to be passed down to the discovery display components
     * @param {*} settings 
     */
    const settings = (settings) => {

        let theSelectedTeam, theSelectedEpic, theSelectedFeature, theSelectedStory = {settings};

        if(theSelectedTeam){
            setSelectedTeam(theSelectedTeam);
        }
        if(theSelectedEpic){
            setSelectedEpic(theSelectedEpic);
        }
        if(theSelectedFeature){
            setSelectedFeature(theSelectedFeature);
        }
        if(theSelectedStory){
            setSelectedStory(theSelectedStory);
        }
    }

    const resetTo = (level) => {

        if(level === "epic"){
            setSelectedFeature(IGNORABLE);
            setSelectedStory(IGNORABLE);
        }
        if(level === "feature"){
            setSelectedStory(IGNORABLE);
        }
        if(level === "clear"){
            setSelectedEpic(IGNORABLE);
            setSelectedFeature(IGNORABLE);
            setSelectedStory(IGNORABLE);
        }
    }

    const DecideView = () =>{

        if(selectedStory !== IGNORABLE){
            return <div>Story Discovery</div>
        }
        if(selectedFeature !== IGNORABLE){
            return <div>Feature Discovery</div>
        }
        if(selectedEpic !== IGNORABLE){
            return <EpicDiscovery epicID={selectedEpic} settings={settings}/>
        }
        return <DiscoveryInstructions/>
    }

    const newEpic = () => {
        discoveryService.newEpic().then(response => {
            let epic = response.data;
            resetTo("clear");
            //add to the epics list
            let newEpicList = [...epicList];
            newEpicList.push(epic);
            setEpicList(newEpicList);
            //then set the selected epic
            setSelectedEpic(epic._id);
        });        
    }

    const trim = (string) => {
        const MAX_LENGTH = 30;
        const ELIPSIS = "...";

        if(!string) return "";
        if(string.length <= MAX_LENGTH) return string;

        let newString = string.slice(0, MAX_LENGTH - ELIPSIS.length);
        return newString+ELIPSIS;
    }

    return (
        <div className={classes.board}>
                <div id="controls" style={{ display: "flex", justifyContent: "flex-start", gap: "10px", verticalAlign: "middle" }}>
                    <select
                        style={{ minWidth: MINSELECTWIDTH, margin: 0 }}
                        value={selectedTeam}
                        onChange={(event) => {
                            setSelectedTeam(event.target.value);
                        }}>
                            <option key={IGNORABLE} value={IGNORABLE}>Select Team</option>
                            {
                                teamList.map(function (team) {
                                    return <option key={team._id} value={team._id}>{team.name} {(team.active ? "" : " (Inactive)")}</option>
                                })
                            }
                    </select>
                    <select
                        style={{ minWidth: MINSELECTWIDTH, margin: 0 }}
                        value={selectedEpic}
                        onChange={(event) => {
                            setSelectedEpic(event.target.value);
                        }}>
                            <option key={IGNORABLE} value={IGNORABLE}>Select Epic</option>
                        {
                            epicList.map(function (epic) {
                                return <option key={epic._id} value={epic._id}>{trim(epic.name)} {(epic.active ? "" : " (Inactive)")}</option>
                            })
                        }
                    </select>
                    <select
                        style={{ minWidth: MINSELECTWIDTH, margin: 0 }}
                        value={selectedFeature}
                        onChange={(event) => {
                            setSelectedFeature(event.target.value);
                        }}>
                            <option key={IGNORABLE} value={IGNORABLE}>Select Feature</option>
                        {
                            featureList.map(function (feature) {
                                return <option key={feature._id} value={feature._id}>{trim(feature.name)} {(feature.active ? "" : " (Inactive)")}</option>
                            })
                        }
                    </select>
                    <select
                        style={{ minWidth: MINSELECTWIDTH, margin: 0 }}
                        value={selectedStory}
                        onChange={(event) => {
                            setSelectedStory(event.target.value);
                        }}>
                            <option key={IGNORABLE} value={IGNORABLE}>Select Story</option>
                        {
                            storyList.map(function (story) {
                                return <option key={story._id} value={story._id}>{trim(story.name)} {(story.active ? "" : " (Inactive)")}</option>
                            })
                        }
                    </select>
                    
                <FormControl variant="filled" style={{display: "none"}}>
                    <TextField value={searchterm} id="search" label="Search backlog" variant="outlined" size="small" style={{marginLeft: "1em", marginRight: "1em"}}
                    onChange={(event) => {
                        setSearchTerm(event.target.value)
                    }}/>
                </FormControl>
                    <FormControl variant="filled" style={{display: "none"}}>
                        <FormControlLabel control={
                            <Switch 
                                checked={Boolean(includeInactive)} 
                                onChange={(event)=>{
                                    setIncludeInactive(!includeInactive)
                            }}/>} label="Include Inactive" />
                    </FormControl>
                    <FormControl variant="filled">
                        <Button id="createNewEpic" size="small" variant="contained" startIcon={<AddCircleIcon />} onClick={newEpic}>New Epic</Button>
                    </FormControl>

                    <FormControl variant="filled">
                        <Button id="clear" size="small" variant="contained" onClick={()=>{resetTo("clear")}}>Clear</Button>
                    </FormControl>

                </div>

                <div className={classes.discoveryArea}>

                    <DecideView/>

                </div>
                
                <div style={{display: "none"}}>
                    <br />Those cards know how to deal with themselves, so a question card knows how to add a new answer and an answer knows how to remove itself.
                    <br />A feature does the same, but with an array of stories... Take inspiration from the discovery tree
                </div>

                <div style={{display: "none"}}>Every action saves back to the DB</div>

        </div>

    )
}