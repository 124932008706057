import React, { Fragment, useState } from 'react'
import { TextField } from "@material-ui/core";
import ToDoService from "../../services/todo.service";
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    dark : {
        color: "black",
        backgroundColor: "white"
    },
    light : {
        color: "white",
        //backgroundColor: "white"
    }
}));


export default function NewTodo(props) {
    const classes = useStyles(props);
    const [isInTodo, setIsInTodo] = useState(window.location.pathname.includes("todo"));
    /**
     * 
     */
    const createTodo = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();

            // Get the text box value
            let title = event.target.value;

            // Call the create todo method in the service object
            ToDoService.createTodo({ title: title }).then(response => {
                // Get the returned data
                let newTodo = response.data;

                // Clear the text box value
                event.target.value = "";

                // We should have a callback so that the bounding component knows what to do with the data that's been returned
                if (props.callBack) {
                    props.callBack(newTodo);
                }

                // And just for completeness sake... return the new todo
                return newTodo;
            });
        }
    }


    const checkAllowed = () =>{
        window.location.pathname.includes("todo") ? setIsInTodo(true) : setIsInTodo(false);
    }

    return (
        <Fragment>
            <TextField id="newTodo"
                disabled={isInTodo ? true : false}
                label="Add todo"
                variant="outlined"
                size="small"
                style={{ marginLeft: "0em", marginRight: "1em" }}
                onMouseDown={checkAllowed}
                onKeyUp={createTodo}
                className={props && props.theme === "dark" ? classes.dark : classes.light}/>
        </Fragment>
    )
}