import { Grid, Typography } from "@material-ui/core";
import React from "react";

export default function FeatureSizing() {
    return(
        <Grid container>
            <Grid item>
                <Typography variant="h2">Feature Sizing</Typography>
            </Grid>
            <Grid item></Grid>
        </Grid>
    )};