import React, { Component } from "react";

import AuthService from "../../services/auth.service";
import { Button, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { AccountCircleRounded, AlternateEmail, LockRounded } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);

    this.state = {
      name: "",
      email: "",
      password: "",
      successful: false,
      message: "",
      nameError: false,
      nameErrorMessage: "",
      emailError: false,
      emailErrorMessage: "",
      passwordError: false,
      passwordErrorMessage: ""

    };
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  validateName(){
    let nameValid = true;
    if("" === this.state.name){
      nameValid = false;
      this.setState({
        nameError : true,
        nameErrorMessage : "Please provide your given name to register"
      });
    } else {
      this.setState({
        nameError : false,
        nameErrorMessage : ""
      });
    }
    return nameValid;
  }

  validateEmail(){
    let emailValid = true;
    if("" === this.state.email){
      emailValid = false;
      this.setState({
        emailError : true,
        emailErrorMessage : "Please provide your email address to register"
      });
    } else {
      this.setState({
        emailError : false,
        emailErrorMessage : ""
      });
    }
    return emailValid;
  }

  validatePassword(){
    let passwordValid = true;
    if("" === this.state.password){
      passwordValid = false;
      this.setState({
          passwordError : true,
          passwordErrorMessage : "Please provide a password to register"
      });
    } else {
      this.setState({
        passwordError : false,
        passwordErrorMessage : ""
      });
    }
    return passwordValid;
  }

  validateAll() {
    return this.validateName() && this.validateEmail() && this.validatePassword();
  }


  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });


    if (this.validateAll()) {
      AuthService.register(
        this.state.name,
        this.state.email,
        this.state.password
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });

          this.props.history.push("/registrationsuccess");
          window.location.reload();

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      console.log('error in handleRegister');
    }
  }

  render() {
    return (
        <Grid container style={{ minHeight: '100vh'}}>
          <Grid item xs={12} sm={6}>
            <img alt="a planning board with sticky notes" src={process.env.PUBLIC_URL + "/planning.jpg"} style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
          </Grid>
          <Grid container item xs={12} sm={6} alignItems="center" direction="column" justifyContent="space-between" style={{padding: 10}}>
            <div />
            <div style={{display:'flex', flexDirection: 'column', maxWidth: 400, minWidth: 300}}>
              <Grid container justifyContent="center">
                <Typography variant="h3">{process.env.REACT_APP_SITE_NAME}</Typography>
              </Grid>
              <TextField value={this.state.name} label="name" margin="normal" onChange={this.onChangeName} onBlur={this.validateEmail} InputProps={{ startAdornment : <InputAdornment position="start"><AccountCircleRounded/></InputAdornment>}} error={this.state.nameError} helperText={this.state.nameErrorMessage}/>
              <TextField value={this.state.email} label="email address" margin="normal" onChange={this.onChangeEmail} onBlur={this.validateEmail} InputProps={{ startAdornment : <InputAdornment position="start"><AlternateEmail/></InputAdornment>}} error={this.state.emailError} helperText={this.state.emailErrorMessage}/>
              <TextField value={this.state.password} type="password" label="password" margin="normal" onChange={this.onChangePassword} onBlur={this.validatePassword} InputProps={{ startAdornment : <InputAdornment position="start"><LockRounded/></InputAdornment>}} error={this.state.passwordError} helperText={this.state.passwordErrorMessage}/>
              <div style={{height:20}}/>
              <Button color="primary" variant="contained" onClick={this.handleRegister}>Register</Button>
              <div style={{height:20}}/>
              <Button component={Link} to="/login">Go to Login</Button>
            </div>
            <Grid container justifyContent="center" spacing={3}>
              
            </Grid>
          </Grid>
        </Grid>
    );
  }
}