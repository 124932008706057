import React, { useEffect, useState } from 'react'
import parse from "html-react-parser"
import { makeStyles } from '@material-ui/core/styles'
import discoveryService from '../../services/discovery.service';
import { Link } from 'react-router-dom';

const NL = `\n`;
const IGNORABLE = "000000000000000000000000";
const useStyles = makeStyles((theme) => ({
    backlogItemBox: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "1em",
        marginBottom: "1em",
        minWidth: "50%",
        overflowX: "scroll",
    },
    backlogItemBoxLabel: {
        backgroundColor: "gold",
        color: "black",
        fontWeight: "bold",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "2em",
        padding: "7px",
        
        minWidth: "24em",
        maxWidth: "36em",
        minHeight: "6em",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },

    details: {
        minWidth:"90%",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "stretch",
        gap: "1.5em",
        minHeight: "400px"
    },

    questionsColumm: {
        flexGrow: "0",
        display: "flex",
        flexDirection: "column",
        align: "right",

    },

    rulesColumm: {
        flexGrow: "3",
        display: "flex",
        flexDirection: "column",
        align: "center",
    },

    featuresColumm: {
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        align: "left",
    },

    smallCard: {
        padding: "7px",
        width: "16em",
        minHeight: "9em",
        marginBottom: "1em",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        transform: "rotate(0deg)",
    },
    ruleCard: {
        backgroundColor: "lightblue",
        color: "black",
    },
    exampleCard: {
        backgroundColor: "lightgreen",
        color: "black",
    },
    questionCard: {
        backgroundColor: "pink",
        color: "black",
    },
    featureCard: {
        backgroundColor: "gold",
        color: "black",
    },

    titleBlock: {
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        backgroundColor: "darkgrey",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        padding: "3px",
        marginBottom: "1em",
        borderRadius: "5px",
        height: "2em",
        lineHeight: "2em",
    },

    addButton: {
        borderRadius: "5px",
    },

    circleButton : {
        marginLeft: "2px",
        textAlign: "center",
        verticalAlign: "middle",
        margin: "auto",
        padding: "0",
        display: "inline-block",
        height: ".9em",
        width: ".9em",
        lineHeight: ".9em",
        border: "none",
        borderRadius: "50%",
        backgroundColor: "grey",
        color: "white",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
        '&:hover': {
            cursor: "default",
          }
    },


    swing : {
        transform: "rotate(90deg) scale(1) translate(-30px, 0px)",
        transformOrigin: "bottom left",
        minWidth: "150px",
        maxWidth: "250px",
        display: "inline-block",
    },
    exportButton: {
        marginBottom: "1em",
        borderRadius: "5px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
        '&:hover': {
            cursor: "default",
          }
    }

}));

export default function EpicDiscovery(props) {
    const classes = useStyles();
    const [didMount, setDidMount] = useState(false);
    const [backlogItem, setBacklogItem] = useState(null);
    const [questionsVisibility, setQuestionsVisibility] = useState(true);
    const [rulesVisibility, setRulesVisibility] = useState(true);
    const [featuresVisibility, setFeaturesVisibility] = useState(true);

    /**
     * If the backlogItem state var changes, we need to save the backlogItem to the DB
     * So we'll invoke 
     */
    useEffect(() => {
        if (!didMount) {
            return;
        }
        try{
            discoveryService.saveEpic(backlogItem).then(response => {
                // No UI effect needed
            });
        }catch(err){
            console.err(err);
        }
    }, [backlogItem]);

    useEffect(() => {
        if(props.epicID === IGNORABLE) return;

        if (!didMount) {
            try{
                discoveryService.getEpic(props.epicID).then(response => {
                    setBacklogItem(response.data);
                });
            }catch(err){
                //console.dir(err);
            }
        }
        setDidMount(true);
    });

    useEffect(() => {
        try{
            discoveryService.getEpic(props.epicID).then(response => {
                setBacklogItem(response.data);
            });
        }catch(err){
            console.dir(err);
        }
    }, [props.epicID]);

    const updateQuestion = (question, index, text) => {
        // ONLY update if the text has changed
        if(text === question.question){
            return;
        }
        // Set up the object with the right text - making sure that we preserve the other elements
        let newQuestion = {...question, question: text};
        // Get the backlog item questions array
        let tempArray = backlogItem.questions;
        // Splice the new object into the array
        tempArray.splice(index, 1, newQuestion);
        setBacklogItem({...backlogItem, questions: tempArray});
    }
    const updateRule = (rule, index, text) => {
        // ONLY update if the rule text has changed
        if(text === rule.rule){
            return;
        }
        // Set up the rule object with the right text - making sure that we preserve the other elements
        let newRule = {...rule, rule: text};
        // Get the backlog item rules array
        let tempArray = backlogItem.rules;
        // Splice the new rule object into the array
        tempArray.splice(index, 1, newRule);
        setBacklogItem({...backlogItem, rules: tempArray});
    }

    const updateExample = (rule, example, ruleIndex, exampleIndex, text) => {
        // ONLY update if the text has changed
        if(text === example.example){
            return;
        }
        // Set up the object with the right text - making sure that we preserve any other elements
        let newExample = {...example, example: text};
        let tempExamplesArray = rule.examples;
        tempExamplesArray.splice(exampleIndex, 1, newExample);
        let newRule = {...rule, examples: tempExamplesArray};
        // Now we've amended the rule object
        // So we need to go in and update that rule instance

        // Get the backlog item rules array
        let tempArray = backlogItem.rules;
        // Splice the new rule object into the array
        tempArray.splice(ruleIndex, 1, newRule);
        setBacklogItem({...backlogItem, rules: tempArray});
    }

    const addQuestion = () => {
        // get a copy of the backlogItem questions array
        let copyOfQuestions = backlogItem.questions;
        // pop a new {question: ""} into the array
        copyOfQuestions.push({question: ""});
        // save the backlogItem with the new questions array
        setBacklogItem({...backlogItem, questions: copyOfQuestions})
    }

    const addRule = () => {
        // get a copy of the backlogItem rules array
        let copyOfRules = backlogItem.rules;
        // pop a new {rule: ""} into the array
        copyOfRules.push({rule: ""});
        // save the backlogItem with the new questions array
        setBacklogItem({...backlogItem, rules: copyOfRules})
    }

    const addExample = (rule, ruleIndex) => {
        let newRule = {...rule};
        if(!newRule.examples) newRule.examples = [];
        newRule.examples.push({example: ""});
        let tempArray = [...backlogItem.rules]
        tempArray.splice(ruleIndex, 1, newRule);
        setBacklogItem({...backlogItem, rules: tempArray});
    }

    const addFeature = () => {
        // make a service call to create a new empty feature
        discoveryService.addNewFeatureToEpic(backlogItem).then(response => {
            let feature = response.data;
            let tempFeatureArray = [...backlogItem.features];
            tempFeatureArray.push(feature);
            // make sure that the feature is cross-linked to the epic
            // get a copy of the features array and add this new feature into that
            setBacklogItem({...backlogItem, features: tempFeatureArray});
            console.log("Add feature")
        });
    }

    const deleteQuestion = (index) => {
        // So... Do we need to get the item based on the index?
        let tempArray = [...backlogItem.questions]
        // Splice out from the index for just the one item
        tempArray.splice(index, 1);
        setBacklogItem({...backlogItem, questions: tempArray});
    }

    const deleteRule = (index) => {
        // So... Do we need to get the rule based on the index?
        let tempRules = [...backlogItem.rules]
        // Splice out from the index for just the one item
        tempRules.splice(index, 1);
        setBacklogItem({...backlogItem, rules: tempRules});
    }

    const deleteExample = (ruleIndex, exampleIndex) => {

        let rules = [...backlogItem.rules];
        let rule = rules[ruleIndex];
        let examples = rule.examples.splice(exampleIndex, 1);
        rules.examples = examples;
        rules.splice(ruleIndex, 1, rule);    
        setBacklogItem({...backlogItem, rules: rules});
    }




    const ExportButton = () => {
        

        const bound = (text) => {
            return text+NL+"-----------"+NL;
        }

        const exportBacklogItemDetails = () =>{
            var content = "Number: " + backlogItem.number + NL+
                    "Hypothesis: " + backlogItem.name + NL +
                    "Details: " +backlogItem.description
            return bound(content);
        }

        const exportQuestions = () =>{
            var content = bound("Questions:");
            (backlogItem) && backlogItem.questions.map(function(question, index){
                    content = content + bound(question.question);
                    return true;
            })
            return content;
        }

        const exportRules = () =>{
            var content = bound("Rules:");
            (backlogItem) && backlogItem.rules.map(function(rule, index){
                    content = content + bound(rule.rule);
                    return true;
            })
            return content;
        }

        const exportBoard = () => {
            
            var content = (
                        exportBacklogItemDetails() + NL +
                        exportQuestions() + NL +
                        exportRules()
                        );
            //content = JSON.stringify(content);


            var element = document.createElement("a");
            element.setAttribute("href", "data:text/plain;charset=utf-8,"+encodeURIComponent(content));
            element.setAttribute("download", "export.txt");
            element.style.display = "none";
            document.body.append(element);
            element.click();
            document.body.removeChild(element);

/*
            return (
                
            )
 */   
            //if features, add the features

            //if stories, add the stories

        }

        return (
            <div>
                <div><button className={classes.exportButton} onClick={exportBoard}>Export Discovery Board</button></div>
                <div>You can export this discovery board at any time to get a text-only version of your content.</div>
            </div>
        )
    }

    const GuidanceNotes = () => {

        let guidance = [];
        let returnString = "";

        if(backlogItem && backlogItem.rules  && backlogItem.rules.length > 4){
            guidance.push("You're building in a few rules here. Consider ways to split the backlog item to make each item smaller.");
        }

        if(backlogItem && backlogItem.questions  && backlogItem.questions.length > 4){
            guidance.push("You have a number of open questions. Look to close those out with the team before you start working on this item.");
        }
        
        guidance.map(function(string){
            return (
                returnString += `<p>${string}</p>`
            )
        });

        return parse("<div>"+returnString+"</div>");
    }



    return (
        <>
        <div className={classes.backlogItemBox}>

            <div style={{display: "grid", gridTemplateColumns: "20% 40% 40%", gap: "1em",}}>
                <ExportButton/>
                <div className={classes.backlogItemBoxLabel}>
                    <div contentEditable="true" suppressContentEditableWarning={true}
                        onBlur={(event)=>{setBacklogItem( {...backlogItem, number: event.target.innerText})}}>
                        {(backlogItem ? backlogItem.number : "")}
                    </div>
                    <div contentEditable="true" suppressContentEditableWarning={true}
                        onBlur={(event)=>{setBacklogItem( {...backlogItem, name: event.target.innerText})}}>
                        {(backlogItem ? backlogItem.name : "Loading...")}
                    </div>
                    <div contentEditable="true" suppressContentEditableWarning={true}
                        onBlur={(event)=>{setBacklogItem( {...backlogItem, description: event.target.innerText})}}>    
                        {(backlogItem ? backlogItem.description : "")}
                    </div>
                </div>
                <GuidanceNotes/>
            </div>


            <div className={classes.details}>
                <div className={classes.questionsColumm}>
                    <div className={`${classes.titleBlock} ${(questionsVisibility? "" : classes.swing)}`}>
                        <button title="Open/collapse this column" style={{float:"left"}} onClick={()=>{setQuestionsVisibility(!questionsVisibility)}}>{questionsVisibility ? "Hide" : "Show"}</button>
                        Questions
                        <button title="Add a new question" style={{float:"right", display: (questionsVisibility ? "inline-block" : "none")}} onClick={addQuestion}>Add</button>
                    </div>
                    <div style={{display: (questionsVisibility ? "grid" : "none"), gridTemplateColumns: "50% 50%", gap: "1em",}}>
                    {(backlogItem) && backlogItem.questions.map(function(question, index){
                        return (
                            <div index={index} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}} className={`${classes.smallCard} ${classes.questionCard}`}>
                                <div contentEditable="true" suppressContentEditableWarning={true} onBlur={(event)=>{updateQuestion(question, index, event.target.innerText)}}>
                                    {question.question}
                                </div>
                                <div style={{marginTop: "3px"}}>
                                    <button title="Delete this question" className={classes.circleButton} style={{float: "left",}} onClick={()=>{deleteQuestion(index)}}>-</button>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                </div>
                <div className={classes.rulesColumm}>
                    <div className={`${classes.titleBlock} ${(rulesVisibility? "" : classes.swing)}`}>
                        <button title="Open/collapse this column" style={{float:"left"}} onClick={()=>{setRulesVisibility(!rulesVisibility)}}>{rulesVisibility ? "Hide" : "Show"}</button>
                        Rules
                        <button title="Add a new rule" style={{float:"right", display: (rulesVisibility ? "inline-block" : "none")}} onClick={addRule}>Add</button>
                    </div>
                    <div style={{display: (rulesVisibility ? "flex" : "none"), flexDirection: "row", gap: "1em", justifyContent: "center",}}>
                        {backlogItem && backlogItem.rules.map(function(rule, index){
                            return (
                                <div key={rule.rule + index}>

                                    <div index={index} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}} className={`${classes.smallCard} ${classes.ruleCard}`}>
                                        <div contentEditable="true" suppressContentEditableWarning={true} onBlur={(event)=>{updateRule(rule, index, event.target.innerText)}}>
                                            {rule.rule}
                                        </div>
                                        <div style={{marginTop: "3px"}}>
                                            <button title="Delete this rule and any attached examples" className={classes.circleButton} style={{float: "left",}} onClick={()=>{deleteRule(index)}}>-</button>
                                            <button title="Add an example to this rule" className={classes.circleButton} style={{float: "right",}} onClick={()=>{addExample(rule, index)}}>+</button>
                                        </div>
                                    </div>
                                    <div>
                                    {rule.examples && rule.examples.map(function(example, exampleIndex){
                                        return (
                                            <div key={example.example + exampleIndex} index={exampleIndex} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}} className={`${classes.smallCard} ${classes.exampleCard}`}>
                                                <div contentEditable="true" suppressContentEditableWarning={true} onBlur={(event)=>{updateExample(rule, example, index, exampleIndex, event.target.innerText)}}>
                                                    {example.example}
                                                </div>
                                                <div style={{marginTop: "3px"}}>
                                                    <button title="Delete this example" className={classes.circleButton} style={{float: "left",}} onClick={()=>{deleteExample(index, exampleIndex)}}>-</button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={classes.featuresColumm}>
                    <div className={`${classes.titleBlock} ${(featuresVisibility? "" : classes.swing)}`}>
                        <button title="Open/collapse this column" style={{float:"left"}} onClick={()=>{setFeaturesVisibility(!featuresVisibility)}}>{featuresVisibility ? "Hide" : "Show"}</button>
                        Features
                        <button title="Add a new feature" style={{float:"right", display: (featuresVisibility ? "inline-block" : "none")}} onClick={addFeature}>Add</button>
                    </div>
                    <div style={{display: (featuresVisibility ? "flex" : "none"), flexDirection: "column", gap: "1em", justifyContent: "center",}}>
                    {(backlogItem) && backlogItem.features.map(function(feature){
                        return (
                            <div key={feature._id} className={`${classes.smallCard} ${classes.featureCard}`}>
                                <Link to={"/discovery/feature?feature="+feature._id}>
                                Feature:<br/>
                                {feature.name}<br/>
                                {feature.description}
                                </Link>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}