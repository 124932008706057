import { Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useEffect, useState } from "react";
import MaintainOrgsScreen from './maintain.orgs.component';
import MaintainTeamsScreen from './maintain.teams.component';
import MaintainUsersScreen from './maintain.users.component';


const useStyles = makeStyles((theme) => ({

    board : {
        
    },

}));

export default function AdminScreen(props) {
    const classes = useStyles(props);
    const [didMount, setDidMount] = useState(false);
    const [value, setValue] = useState("3");
    const handleChange = (event, newValue) => {
        setValue(newValue);
     };
    
    //TODO: Don't do this on the first one...
    /**
     * If the state changes, save to the back end
     */
    useEffect(() => {
        if(didMount){

        }
        setDidMount(true);
    }, [])

    return(
        <TabContext value={value} className={classes.board}>
            <TabList onChange={handleChange}>
                <Tab label="Manage Organisations" value="1"/>
                <Tab label="Manage Teams" value="2"/>
                <Tab label="Manage Users" value="3"/>
                <Tab label="Manage Config" value="4"/>
            </TabList>

            <TabPanel value="1" index={0}>
                <MaintainOrgsScreen/>
            </TabPanel>
            <TabPanel value="2" index={1}>
                <MaintainTeamsScreen/>
            </TabPanel>
            <TabPanel value="3" index={2}>
                <MaintainUsersScreen/>
            </TabPanel>
            <TabPanel value="4" index={3}>
                Config
            </TabPanel>


        </TabContext>
    )
};