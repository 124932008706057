import React, { Component } from "react";
import { Typography, Paper, TextField, Button } from "@material-ui/core";
import TodoList from "./todolist.component";
import ToDoService from "../../services/todo.service";
import {DragDropContext} from 'react-beautiful-dnd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

/*
 * Should be a class so we get easy access to lifecycle events
 */
export default class Todos extends Component {

  state = {
      stack: [],
      pending: [],
      progress: [],
      done: [],
      archived: [],
      isLoading: true,
      search: ""
  };

  componentDidMount() {
    ToDoService.getToDos().then(response => {
      var tasks = response.data.todos;
      
      this.setState({stack: tasks.filter(obj => {
        return obj.state === "stack";
      })});

      this.setState({pending: tasks.filter(obj => {
        return obj.state === "pending";
      })});

      this.setState({progress: tasks.filter(obj => {
        return obj.state === "progress";
      })});

      this.setState({done: tasks.filter(obj => {
        return obj.state === "done";
      })});

      this.setState({archived: tasks.filter(obj => {
        return obj.state === "archived";
      })});

      this.setState({ isLoading: false });

    });
  }
    
  componentWillUnmount() {
    //window.removeEventListener('scroll', this.handleScroll.bind(this));
    //window.removeEventListener("resize", this.drawStuff.bind(this));
  }
  
  componentDidUpdate(prevProps, prevState) {
  }

  handleDragEnd(result){
    if(!result.destination) return;
    try{
      // If it's been dragged to a new list
      if(result.source.droppableId !== result.destination.droppableId){

        const sourceArray = [...this.state[result.source.droppableId]];
        const destinationArray = [...this.state[result.destination.droppableId]];
        const draggedItem = sourceArray.splice(result.source.index, 1)[0];
        draggedItem.state = result.destination.droppableId;
        draggedItem.order = result.destination.index;
        destinationArray.splice(result.destination.index, 0, draggedItem);
        
        this.updateTodo(draggedItem);

        //TODO: Now go reset the order field of each item in both of the amended lists


        // Now save the state back
        this.setState({
          [result.source.droppableId]: sourceArray, 
          [result.destination.droppableId]: destinationArray});
      } else {
        const sourceArray = [...this.state[result.source.droppableId]];
        const draggedItem = sourceArray.splice(result.source.index, 1)[0];
        draggedItem.order = result.destination.index;
        sourceArray.splice(result.destination.index, 0, draggedItem);
        
        this.updateTodo(draggedItem);

        //TODO: Now go reset the order field of each item in the amended list

        // Now save the state back
        this.setState({[result.source.droppableId]: sourceArray});
      }
      //this.setState({tasks: newArray});
    }catch(e){
      console.error(e);
    }
  }

  /**
   * TODO: This is too tied to the UI - need to pull this out and do a clean call to the service
   */
  createTodo = (event) =>{
    if(event.keyCode === 13){
      event.preventDefault();

      // Get the text box value
      let title = event.target.value;

      // Call the create todo method in the service object
      ToDoService.createTodo({title: title}).then(response => {
        // Get the returned data
        let newTodo = response.data;
        // Add it to the state list of tasks - it should be pending, but check the todo.state first and add it to the right list

        this.setState({pending: [...this.state.pending, newTodo]});

        // Clear the text box value
        event.target.value = "";
      });
    }
  }

  /**
   * Give the user the option to clear-down the archive
   * @param {*} event 
   */
  clearTheArchive = (event) => {
    // Grab the archive list
    // Loop through the array and call a delete function on the service for each item in the array
    // Clear the state array
    this.setState({archived: []});
    ToDoService.clearArchive();
  }

  /**
   * 
   * @param {*} todo 
   * @returns 
   */
  updateTodo = (todo) => {
    if(todo === undefined) return;
    if(todo._id === undefined) return;
    console.trace();
    ToDoService.updateTodo(todo).then(response => {

    });

  }

  filterList = (tasks) => {

    //get the search string...

    let filteredList = [];

    // we need to map the todos from the provided list into the filtered list

    filteredList = tasks.filter(todo => todo.title.toLowerCase.includes("to".toLowerCase()));

    // We are NOT changing the state! We just want to filter what we see on the screen
    // So we'll wrap the given task list in this function?

    return filteredList;
  }


  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <React.Fragment>
        <div style={{marginLeft: "1em", marginRight: "1em", marginTop: "1em"}}>
          <Typography variant="body1">
            You can create your own list of todos here and then drag and drop between the states. Adding tags will help make your snippets more searchable.
          </Typography>
          </div>
          <div style={{"margin" : "1em"}}>
            <TextField id="newTodo" label="Add todo" variant="outlined" size="small" style={{marginLeft: "0em", marginRight: "1em"}}onKeyUp={this.createTodo}/>
            <TextField id="searchTodos" label="Search todos" variant="outlined" size="small" style={{marginLeft: "1em", marginRight: "1em"}}
            onChange={(event) => {
              let text = event.target.value;
              this.setState({search: text})
            }}/>
            <Button variant="contained" color="secondary" onClick={this.clearTheArchive} style={{float: "right"}} startIcon={<DeleteForeverIcon />}>Clear the archive</Button>
          </div>

          <div style={{marginLeft: "1em", marginRight: "1em", marginBottom: "2em"}}>
          <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
          <div style={{

              display: "grid",
              gridAutoFlow: "column",
              gridAutoColumns: "1fr",
              gap: "1rem",
            }}>

              <Paper elevation={12}><TodoList state={"stack"} label={"The Stack"} tasks={this.state.stack} search={this.state.search.toLowerCase()}/></Paper>
              <Paper elevation={12}><TodoList state={"pending"} label={"Pending"} tasks={this.state.pending} search={this.state.search.toLowerCase()}/></Paper>
              <Paper elevation={12}><TodoList state={"progress"} label={"In Progress"} tasks={this.state.progress} search={this.state.search.toLowerCase()}/></Paper>
              <Paper elevation={12}><TodoList state={"done"} label={"Done"} tasks={this.state.done} search={this.state.search.toLowerCase()}/></Paper>
              <Paper elevation={12}><TodoList state={"archived"} label={"Archived Tasks"} tasks={this.state.archived} search={this.state.search.toLowerCase()}/></Paper>

            </div>
          </DragDropContext>
        </div>
    </React.Fragment>
    );
  }
}