import React, { useState } from 'react'
import Tab from '@material-ui/core/Tab';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DiscoveryPrompt from './discoveryprompt.component';
import DiscoveryBoard from './discoveryboard.component';

export default function Discovery() {
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <TabContext value={value}>
            <TabList onChange={handleChange}>
                <Tab label="Discovery Board" value="1" />
                <Tab label="Prompts" value="2" />
            </TabList>
            <TabPanel value="1" index={0} style={{paddingLeft: "0", paddingRight: "0"}}>
                <DiscoveryBoard />
            </TabPanel>
            <TabPanel value="2" index={1} style={{paddingLeft: "0", paddingRight: "0"}}>
                <DiscoveryPrompt />
            </TabPanel>
        </TabContext>
    )
}
