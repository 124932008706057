import React, { Component } from "react";

import AuthService from "../../services/auth.service";
import { Button, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { AlternateEmail, LockRounded } from "@material-ui/icons";
import { Link } from "react-router-dom";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePassword = this.validatePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
      usernameError: false,
      passwordError: false,
      usernameErrorMessage: "",
      passwordErrorMessage: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  validateEmail(){
    let emailValid = true;
    if("" === this.state.username){
      emailValid = false;
      this.setState({
        usernameError : true,
        usernameErrorMessage : "Please provide your email address to log in"
      });
    } else {
      this.setState({
        usernameError : false,
        usernameErrorMessage : ""
      });
    }
    return emailValid;
  }

  validatePassword(){
    let passwordValid = true;
    if("" === this.state.password){
      passwordValid = false;
      this.setState({
          passwordError : true,
          passwordErrorMessage : "Please provide your password to log in"
      });
    } else {
      this.setState({
        passwordError : false,
        passwordErrorMessage : ""
      });
    }
    return passwordValid;
  }

  validateAll() {
    return this.validateEmail() && this.validatePassword();
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    
    if (this.validateAll()) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          //TODO: Is this a problem?
          this.props.history.push("/home");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (  
      <Grid container style={{ minHeight: '100vh'}}>
        <Grid item xs={12} sm={6}>
          <img alt="a planning board with sticky notes" src={process.env.PUBLIC_URL + "/planning.jpg"} style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
        </Grid>
        <Grid container item xs={12} sm={6} alignItems="center" direction="column" justifyContent="space-between" style={{padding: 10}}>
          <div />
          <div style={{display:'flex', flexDirection: 'column', maxWidth: 400, minWidth: 300}}>
            <Grid container justifyContent="center">
              <Typography variant="h3">{process.env.REACT_APP_SITE_NAME}</Typography>
            </Grid>
            <TextField value={this.state.username} label="email address" margin="normal" onChange={this.onChangeUsername} onBlur={this.validateEmail} InputProps={{ startAdornment : <InputAdornment position="start"><AlternateEmail/></InputAdornment>}} error={this.state.usernameError} helperText={this.state.usernameErrorMessage}/>
            <TextField value={this.state.password} type="password" label="password" margin="normal" onChange={this.onChangePassword} onBlur={this.validatePassword} InputProps={{ startAdornment : <InputAdornment position="start"><LockRounded/></InputAdornment>}} error={this.state.passwordError} helperText={this.state.passwordErrorMessage}/>
            <div style={{height:20}}/>
            <Button color="primary" variant="contained" onClick={this.handleLogin}>Log in</Button>
            <div style={{height:20}}/>
            <Button component={Link} to="/register">Sign Up</Button>
          </div>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button variant="outlined">Forgot Password</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}