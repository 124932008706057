import React, { Component } from "react";

export default class Development extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }


  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          Development Support
        </header>
        

      </div>
    );
  }
}