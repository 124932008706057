import React from 'react'
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    sidebar : {
        marginRight: "1em",
        paddingRight: "1em"
    },
    displayArea : {
        paddingLeft: "1em" 
    }
}));

export default function Journal(props) {

    const classes = useStyles(props);

    return (
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        <h1>Development Journal</h1>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div id="sidebar" className={classes.sidebar}>
                        <TextField id="search" label="Search your journal" variant="outlined" />
                        <TreeView
                            aria-label="Your dev journal"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                            >
                            <TreeItem nodeId="1" label="Applications">
                                <TreeItem nodeId="2" label="Calendar" />
                            </TreeItem>
                            <TreeItem nodeId="5" label="Documents">
                                <TreeItem nodeId="10" label="OSS" />
                                <TreeItem nodeId="6" label="MUI">
                                <TreeItem nodeId="8" label="index.js" />
                                </TreeItem>
                            </TreeItem>
                        </TreeView>
                    </div>                    
                </Grid>
                <Grid item xs={9}>
                    <div id="journalDisplayArea" className={classes.displayArea}>
                        <p>List of journal entry dates/months/years going further back in time...</p>
                        <p>Add journal entry</p>
                        <p>Edit journal entry</p>
                        <p>Allow image embeds?</p>
                        <p>Search for keyword in the entries</p>
                        <p>Allow for hashtags and provide a group-by hashtag function</p>
                    </div> 
                    
                </Grid>
            </Grid>
        </div>
    )
}

