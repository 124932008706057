import React, { Component } from "react";
import DiscoveryService from "../../services/discovery.service";
import DiscoveryNode from "./discoverynode.component";

export default class DiscoveryTree extends Component {

  state = {
    currentTree: null,
    treeUUID: null,
    name: "No tree loaded...",
  };

  constructor(props) {
    super(props);
    this.setState({treeUUID: props.uuid});
    this.setState({currentTree: props.tree});
    this.CANVASID = 'canvas';
    this.DOT_RADIUS = 5;
    this.LINE_WIDTH = 1.5;
    this.CONNECTOR_COLOR = "black";

    this.lines = [];
    this.scrollTop = 0;
    this.scrollLeft = 0;

    this.updateTree = this.updateTree.bind(this);
    this.updateNode = this.updateNode.bind(this);
    this.saveNewTree = this.saveNewTree.bind(this);
    this.drawStuff = this.drawStuff.bind(this);
    this.handleScroll = this.handleScroll.bind(this)
  }

  /**
   * Save the current tree to the DB
   */
  updateTree(){
    const { currentTree } = this.state;
    this.props.controls.updateTree(currentTree);
  };

  /**
   * Save a new tree
   * TODO: Delegate to the controls object
   */
   saveNewTree(){
    const { uuid, name, currentTree } = this.state;
    DiscoveryService.newDecisionTree({
      uuid: uuid,
      name: name,
      nodes: [
        currentTree
      ]
    }).then(response => {
      console.log(response);
    });
  };

  /**
   * TODO: This needs to chain up to the discoveryPrompt parent
   * @param {*} newNode 
   */
  updateNode(newNode){
    this.setState({currentTree: newNode}, ()=>{
      this.props.controls.updateNode(this.state.currentTree);
    });
  };


  /*
   * When we've mounted this component, we need to go fetch the data
   * So we have a 
   */
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.drawStuff);

    // Only try to load the tree if we have a UUID
    if(this.state.treeUUID !== null){
      this.loadtree();
    }

  }
  

  loadtree(){
    DiscoveryService.getDecisionTree( this.state.treeUUID ).then(response => {
      this.setState({ currentTree: response.data.nodes[0] });
      this.setState({ uuid: response.data.uuid});
      this.setState({ name: response.data.name});
      this.setState({ isLoading: false });
    });
  }

  /*
   * Remove the window listeners once we're done with this component
   */
  componentWillUnmount() {
    window.removeEventListener('scroll',  this.handleScroll);
    window.removeEventListener("resize",  this.drawStuff);
  }
  
  /*
   * If anything in the component updates, we need to redraw the canvas element
   */
  componentDidUpdate(prevProps, prevState) {

    // Check the props changes
    if(this.props.uuid !== prevProps.uuid){
      this.setState({treeUUID: this.props.uuid});
    }

    // Now look for the state change
    if(this.state.treeUUID !== prevState.treeUUID){
      this.loadtree();
    }

    // Now we have some UI stuff to go and do
    try {
      this.drawStuff(); 
    } catch (error) {
      //console.error(error);
    }
  }



  render() {
    const { name, currentTree} = this.state;
    if (currentTree === null) {
      return (
        <React.Fragment>
          <canvas id="canvas" style={{position: "absolute", top: "0", left: "0", zIndex: "-1"}}> </canvas>          
          <div><h2>Select a discovery tree to start.</h2></div>
        </React.Fragment> 
      )
    }
    return (
      <React.Fragment>
        <canvas id="canvas" style={{position: "absolute", top: "0", left: "0", zIndex: "-1"}}> </canvas>
        <div>
          <div><h2 style={{textDecoration: "underline", textDecorationThickness: "3px"}}>{name}</h2></div>
        </div>
        <div id="treeViewDiv" style={{alignItems: "center", alignContent: "center", justifyContent: "center", textAlign: "center", whiteSpace: "nowrap"}}>
          <DiscoveryNode key={currentTree.uuid} node={currentTree} level={0} updateNode={this.updateNode} saveTree={this.updateTree} saveNew={this.saveNewTree}/>
        </div>
      </React.Fragment>
    );
  }

/**
* Use this function to draw a connecting dot at the given coordinates
*/
drawConnectingDot(point) {
  let canvas = document.getElementById(this.CANVASID);
  let context = canvas.getContext("2d");
  context.beginPath();
  context.arc(point.x, point.y, this.DOT_RADIUS, 0, 2 * Math.PI, false);
  context.fillStyle = this.CONNECTOR_COLOR;
  context.fill();
};

/**
* We will need to resize the canvas element from time to time
*/ 
resizeCanvas() {
  try{
    let canvas = document.getElementById(this.CANVASID);
    canvas.width = document.documentElement.scrollWidth;
    canvas.height = document.documentElement.scrollHeight;
  }catch(e){
    //ignore
  }
};

getTreeNodeChildren = (element) => {

  if(!element) return;
  if(!element.childNodes) return;
  // If we don't have a nodescontainer, then just return
  if (element.getElementsByClassName("nodescontainer").length === 0) {
      return;
  }

  let nodesContainer = element.getElementsByClassName("nodescontainer")[0];

  return nodesContainer.childNodes;
};

/**
 * Base our visibillity check on the closed style class
 * @param {*} element 
 * @returns 
 */
areNodeChildrenVisible = (element) => {
  if (element.childNodes.length === 1) {
      return;
  }
  let nodesContainer = element.childNodes[1];
  return !nodesContainer.classList.value.includes("closed");
};


/**
 * Recursive function that looks for the bottom point of the passed element and the top point of any children
 * And then does the same thing for any child elements
 * And keeps doing it until the whole tree is done
 * @param {*} element 
 * @returns 
 */
getPointsFromParentToChildren(element) {
  if (element === undefined) return;
  // If we have passed an element in, we need to see if it has children
  let children = this.getTreeNodeChildren(element);
  if (children === undefined) return;

  //element.childNodes;
  let startPoint;

  if(element.classList.contains('expandable')){
    startPoint = this.getBottomPoint(element.firstChild);
    this.drawConnectingDot(startPoint);
  }

  if(!this.areNodeChildrenVisible(element)) return;

  // Loop through, draw the dot, add the line to the array, then explore any children
  for (let i = 0; i < children.length; i++) {
      let child = children[i];
      if (!child.classList.contains('closed')) {
          // And now get the top point for each OPEN child
          // Add point to the list
          // Add a Line object from bottom to THIS top
          let thisPoint = this.getTopPoint(child);
          this.drawConnectingDot(thisPoint);
          this.lines.push({start: startPoint, end: thisPoint});
          this.getPointsFromParentToChildren(child);
      }
  }
};

/**
* Work out the top-middle point for the given element
*/ 
getTopPoint = (element) => {
  var rect = element.getBoundingClientRect();
  let centerX = this.scrollLeft + rect.left + (rect.right - rect.left) / 2;
  let centerY = this.scrollTop + rect.top + 10;
  return {x: centerX, y: centerY};
};

/**
* Work out the bottom-middle point for the given element
*/ 
getBottomPoint = (element) => {
  var rect = element.getBoundingClientRect();
  let centerX = this.scrollLeft + rect.left + (rect.right - rect.left) / 2;
  let centerY = this.scrollTop + rect.bottom;
  return {x: centerX, y: centerY};
};

/**
* Just a wrapper around another function currently - should refactor
*/ 
drawConnectingDots = () => {
  // need to find the element with level = 0
  let firstNode = document.querySelector('[level="0"]');
  this.getPointsFromParentToChildren(firstNode);
};

/**
* Hacky as we're using a global lines var rather than passing the variable in...
*/ 
drawConnectingLines() {
  try{
    let canvas = document.getElementById(this.CANVASID);
    var context = canvas.getContext("2d");
    for (let i = 0; i < this.lines.length; i++) {
        context.beginPath();
        context.strokeStyle = this.CONNECTOR_COLOR;
        context.lineWidth = this.LINE_WIDTH;
        context.moveTo(this.lines[i].start.x, this.lines[i].start.y);
        context.lineTo(this.lines[i].end.x, this.lines[i].end.y);
        context.stroke();
    }
    
  }catch(error){

  }
  // Hacky...
  // Remember to clear the lines array so we don't keep drawing and redrawing all the old stuff
  // Shouldn't need to persist this at the global level
  this.lines = [];
};

/**
* Clear the canvas of all points and lines
*/ 
deleteTheLines() {
  try{
    let canvas = document.getElementById(this.CANVASID);
    let context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  }catch(error){
    console.error(error);
  }
};

/**
  * Knows how to clear down the canvas content and redraw points and lines to complete the tree
  */ 
 drawStuff() {
  this.resizeCanvas();
  this.deleteTheLines();
  this.drawConnectingDots();
  this.drawConnectingLines();
};

handleScroll(e) {
  this.scrollTop = window.scrollY;
  this.scrollLeft = window.scrollX;
  this.drawStuff();
}

}