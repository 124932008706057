import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_GATEWAY+'api/todo/';

class ToDoService {
  async getToDos() {
    return axios.get(API_URL + 'getTODOS', { headers: authHeader() });
  }

  async createTodo(todo) {
    return axios.post(API_URL + 'createTODO', todo, { headers: authHeader() });
  }

  async updateTodo(todo){
    return axios.post(API_URL + 'updateTodo', todo, { headers: authHeader() });
  }

  async clearArchive(){
    return axios.get(API_URL + 'clearArchive', { headers: authHeader() });
  }

}

export default new ToDoService();