import React, { Component, createElement } from "react";
import { Link } from "react-router-dom";
import { CardActionArea } from "@material-ui/core";
import UserService from "../services/user.service";
import { Card, CardContent, CardHeader, Container, Grid, Typography } from "@material-ui/core";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';


export default class Home extends Component {


  constructor(props) {
    super(props);
    const MuiIcons = new Map();
    MuiIcons.set("QuestionAnswerIcon", QuestionAnswerIcon);
    MuiIcons.set("FormatListBulletedIcon", FormatListBulletedIcon);

    let actions = [
      {id: 6, title:'To Do List', subtitle:'Your running list of dev tasks', description:'Your list of tasks, with notes, reminders, etc.', path:'/todos', icon:"FormatListBulletedIcon"},
      {id: 7, title:'User Story Discovery', subtitle:'Feature breakdown and user story analysis', description:'Break your target feature down into the discrete user stories you need and then analyse those stories so that the team understands the work needed and how it will be tested.', path:'/discovery', icon:"QuestionAnswerIcon"},
      {id: 5, title:'Dev Journal', subtitle:'Running record of work done', description:'Reminders and notes for work completed', path:'/journal'},
      {id: 1, title:'Feature Sizing', subtitle:'Estimating when there are many unknowns', description:'Estimating feature size in t-shirt or story points', path:'/development'},
      {id: 2, title:'User Story Sizing', subtitle:'Working out estimates against units of work', description:'Estimating feature size in t-shirt or story points', path:'/development'},
      {id: 3, title:'User Story Task Breakdown', subtitle:'Break the user story down into low-level tasks', description:'You\'ll be asked to break each user story into tasks, provide estimates in hours, and track those tasks.', path:'/decompose'},
    ];

    this.state = {
      content: "",
      actions: actions,
      MuiIcons: MuiIcons,
    };
  }
  
  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <div className="container" style={{margin: "2em"}}>
        <div style={{width: "75%", marginLeft: "auto", marginRight: "auto"}}>
          <header className="jumbotron">
            <h3>Welcome to AgilePlanning.tech</h3>
          </header>

          <p>AgilePlanning.tech provides a place where you can plan your work, whether that be the long-view, or the up-close development tasks needed for the user story at hand.</p>
          <p>How do you work out how large a feature is when you don't know all the requirements? You know someone's going to ask you, right?</p>
          <p>And what do you need to know about that user story in order to work out the size in story points or hours?</p>
          <p>Then, when you get into the development, what tasks do you need to cycle through? UI, styling, service implementation, unit tests, etc?</p>
          <p>AgilePlanning.tech is <em>your</em> online workbook, except we help fill it in for you. Build out the way you want to plan, and we'll support you and do some of the brain work for you.</p>

          <h3 style={{marginTop: "2em"}}>What do you want to do today?</h3>
        </div>
        
        <div style={{marginTop: "2em"}}>
          <Container>
            <Grid container spacing={3}>
            {this.state.actions.filter((action)=> {
              if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                return true
              } else {
                return action.id === 6 || action.id === 7
              }
            }).map(action => (
              <Grid item key={action.id} xs={6}>
                <Card>
                  <CardActionArea component={Link} to={action.path}>
                    <CardHeader title={action.title} subheader={action.subtitle}>
                    </CardHeader>
                    <CardContent>
                      <div style={{float: "right", position: "absolute", top: "0", right: "0", paddingTop: "1em", paddingRight: "1em"}}>
                        {action.icon !== undefined 
                        ? createElement(this.state.MuiIcons.get(action.icon))
                        : "" }
                      </div>
                      <Typography color="textSecondary">{action.description}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}

            </Grid>
          </Container>
        </div>
        
      </div>
    );
  }
}