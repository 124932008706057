import React from "react";
import { AppBar, Box, Grid, Link } from "@material-ui/core";
import { Typography } from "@material-ui/core";

export default function Footer() {
    return (
        <AppBar position="auto" color="primary">
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="body1" color="inherit">
                            © 2022 AgilePlanning.tech
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="inherit">
                            <Link href="mailto:marklawford@gmail.com" color="inherit">Contact Us</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </AppBar>
    )
}