import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_GATEWAY + 'api/discover/';
const IGNORABLE = "000000000000000000000000";
const DEFAULT_QUESTIONS = [
  {question: "What NFRs do we need to consider?"},
  {question: "Do we need to run in-track performance testing?"},
  {question: "Do we have any target release dates?"},
  {question: "Who else needs to contribute to this functionality?"}]
/**
 * 
 */
class DiscoveryService {

  /**
   * 
   * @returns 
   */
  async getListOfTrees() {
    try {
      return axios.get(API_URL + 'getListOfTrees', { headers: authHeader() })
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * Get the uuid decision tree
   * @param {*} uuid 
   * @returns 
   */
  async getDecisionTree(uuid) {
    let params = "?uuid=" + uuid;
    try {
      return axios.get(API_URL + 'getDecisionTree' + params, { headers: authHeader() });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Save the current decision tree - this is an update function
   * @param {*} tree 
   * @returns 
   */
  async saveDecisionTree(tree) {
    return axios.post(API_URL + 'saveDecisionTree', tree, { headers: authHeader() });
  }

  /**
   * Save a new decision tree
   */
  async newDecisionTree(tree) {
    console.log("Save the new decision tree");
    return null;
  }

  /**
   * Create a new default empty decision tree
   */
  async newDefaultDecisionTree(name) {
    return axios.post(API_URL + 'createEmptyDecisionTree', { name: name }, { headers: authHeader() });
  }

  async archiveDecisionTree() {

  }

  async deleteDecisionTree() {

  }


  /**
   * 
   * @returns 
   */
  async getEpics(teamID) {
    let searchParams = "";
    if (teamID && teamID !== IGNORABLE) {
      searchParams = "?teamID=" + teamID;
      console.log("Getting epics for team: " + teamID);
    }
    try {
      return axios.get(API_URL + 'getEpics' + searchParams, { headers: authHeader() })
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  /**
   * 
   * @returns 
   */
  async getFeatures(teamID, epicID) {
    let searchParams = "";
    let qm = "";
    let amp = "";
    if (teamID && teamID !== IGNORABLE) {
      searchParams = "teamID=" + teamID;
      qm = "?";
      amp = "&";
    }
    if (epicID && epicID !== IGNORABLE) {
      searchParams = searchParams + amp + "epicID=" + epicID;
      qm = "?";
      amp = "&";
    }
    try {
      return axios.get(API_URL + 'getFeatures' + qm + searchParams, { headers: authHeader() })
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  /**
   * 
   * @returns 
   */
  async getStories() {
    try {
      return axios.get(API_URL + 'getStories', { headers: authHeader() })
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getTeamsForUsersOrganisation() {
    try {
      return axios.get(API_URL + 'getTeamsForUsersOrganisation', { headers: authHeader() })
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getEpic(epicID) {
    let searchParams = "";
    searchParams = searchParams + "epicID=" + epicID;
    try {
      return axios.get(API_URL + "getEpic?" + searchParams, { headers: authHeader() })
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  /**
   * Saves the provided epic to the DB
   * @param {*} epic 
   * @returns 
   */
  async saveEpic(epic) {
    try {
      return axios.post(API_URL + "saveEpic", epic, { headers: authHeader() });
    } catch (err) {
      console.err(err);
    }
  }


  /**
   * Called from within discovery to add a new and empty feature into an existing epic
   * @param {*} epic 
   * @param {*} feature 
   */
  async addNewFeatureToEpic(epic){

    const emptyFeature = {
      number: "000001",
      name: "Empty feature",
      description: "",
      epic: epic._id
    };

    try {

      return axios.post(API_URL + "newFeature", emptyFeature, { headers: authHeader() })

    }catch(err){
      console.err(err);
    }
  }

  async newEpic(epic){

    let newEpic = {};
    if(epic){
      newEpic = epic;
    } else {
      newEpic.hypothesis = "Complete the epic hypothesis";
      newEpic.name = "New epic";
      newEpic.description = "Complete the epic description";
      newEpic.version = 1;
      newEpic.questions = DEFAULT_QUESTIONS;
      newEpic.rules = [];
      newEpic.features = [];
    }

    try {
      return axios.post(API_URL + "newEpic", newEpic, { headers: authHeader() })
    }catch(err){
      console.err(err);
    } 
  }

}

export default new DiscoveryService();